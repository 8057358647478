import React, { useState, useEffect } from 'react';
import MaterialTable from 'material-table'
import apiService from '../../../../helpers/apiService';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

const path_url = `${ apiService.API_DOMAIN }/records/play/`;
String.prototype.toHHMMSS = function () {
  var sec_num = parseInt( this, 10 ); // don't forget the second param
  var hours = Math.floor( sec_num / 3600 );
  var minutes = Math.floor( ( sec_num - ( hours * 3600 ) ) / 60 );
  var seconds = sec_num - ( hours * 3600 ) - ( minutes * 60 );

  if ( hours < 10 ) {
    hours = '0' + hours;
  }
  if ( minutes < 10 ) {
    minutes = '0' + minutes;
  }
  if ( seconds < 10 ) {
    seconds = '0' + seconds;
  }
  return hours + ':' + minutes + ':' + seconds;
}

const ActivityLogsTable = props => {
  const [ values ] = useState( {

    columns: [
      { title: 'תאריך', field: 'stime', type: 'datetime', defaultSort: 'desc' },
      { title: 'פעולה', field: 'name', editable: 'never' },
      { title: 'שם תלמיד', field: 'last', editable: 'never', render: row => ( `${ row.last } ${ row.first }` ) },
      { title: 'סמל מוסד', field: 'mosad_id', editable: 'never' },
      { title: 'כיתה', field: 'class_he', render: row => ( `${ row.class_he }/${ row.sub_class_room }` ) },

    ]
  } );
  const [ isLoading, setIsLoading ] = useState( true );
  const [ logsList, setLogList ] = useState( [] );
  const [ dialogStatus, setDialogStatus ] = useState( false );
  const [ singleRecord, setSingleRecord ] = useState( {} );

  function closeDialog() {
    setDialogStatus( false );
  }

  function getActivityLogs() {
    apiService.User.ActivityLogs.Get( props.props?.match?.params?.student ).then( records => {
      setLogList( records.data );
      setIsLoading( false );
    } );
  }

  useEffect( getActivityLogs, [ props.props?.match?.params?.student ] )
  return (
    <div>
      <MaterialTable
        columns={ values.columns }
        data={ logsList }
        isLoading={ isLoading }
        //localization={ { header: { actions: 'בחר' } } }
        options={ {
          filtering: true,
          exportAllData: true,
          pageSize: 20,
          addRowPosition: 'first',
          //selection: true,
        } }
        title={ <h4>לוג פעילות במערכת טלפונית</h4> }
        onRowClick={ ( e, rowData ) => {
          props.props.history.push( `/app/logs/${ rowData.code }` );
        } }
      />
      <Dialog
        aria-labelledby="draggable-dialog-title"
        onClose={ closeDialog }
        open={ dialogStatus }
      >
        <DialogTitle id="draggable-dialog-title">{ singleRecord.id } האזנה </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Listing record: { singleRecord.id }. from { singleRecord.first } { singleRecord.last }.
            (school: { singleRecord.mosadname })
            <h5>activity</h5>
            <audio
              autoPlay
              controls
              src={ `${ path_url }${ singleRecord.conf }/${ singleRecord.mosadpbx || 'xx' }` }
            >
              Your browser does not support the
              <code>audio</code> element.
            </audio>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  )
};


export default ActivityLogsTable;

import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { Grid, Typography } from '@material-ui/core';
import { Budget } from '../../Dashboard/components';
import MenuItem from '@material-ui/core/MenuItem';
import ContactList from './contactList';
import apiService from '../../../helpers/apiService';
import Button from "@material-ui/core/Button";
import { DropzoneDialog } from "material-ui-dropzone";
import ReactAudioPlayer from 'react-audio-player';
import Autocomplete from "@material-ui/lab/Autocomplete";
import Alert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import AlertTitle from "@material-ui/lab/AlertTitle";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Collapse from "@material-ui/core/Collapse";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Divider from "@material-ui/core/Divider";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import Input from "@material-ui/core/Input";
import ListItemText from "@material-ui/core/ListItemText";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";


const useStyles = makeStyles( ( theme ) => ( {
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: theme.spacing( 4 )

  },
  textField: {
    marginLeft: theme.spacing( 1 ),
    marginRight: theme.spacing( 1 )
  },
  card: {
    marginTop: theme.spacing( 1 ),
    marginRight: theme.spacing( 1 ),
    paddingLeft: theme.spacing( 1 ),
    paddingRight: theme.spacing( 1 ),
  }
} ) );
const schema = {
  campaignName: {
    presence: { allowEmpty: false, message: 'is required' },
    email: false,
    length: {
      maximum: 64
    }
  },
  description: {
    presence: { allowEmpty: true, message: 'is required' },
    length: {
      maximum: 250
    }
  },
  mosadID: {
    presence: { allowEmpty: false, message: 'is required' },
  },
  fileID: {
    presence: { allowEmpty: false, message: 'is required' },
  },
  calssRoom: {
    presence: { allowEmpty: true, message: 'is required' },
    length: {
      maximum: 2
    }
  },
  contacts: {
    presence: { allowEmpty: true, message: 'is required' },
    length: {
      maximum: 2
    }
  },
};
const path_url = `${ apiService.API_DOMAIN }/files/play/`;

const MenuProps = {
  PaperProps: {
    style: {
      // maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const classesStrArr = [
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12',
];

export default function FormMessage( props ) {
  const { history } = props;
  const classes = useStyles();
  const [ dialogStatus, setDialogStatus ] = useState( false );
  // const [ fileObject, setFileObject ] = useState( { name: 'לא נבחר קובץ - לחץ לטעינת קובץ' } );

  const [ dialogOpen, setDialogOpen ] = React.useState( false );
  //const [ campaignName, setCampaignName ] = React.useState( '' );

  //const [ fileID, setFileID ] = React.useState( 0 );
  const [ mosad, setMosad ] = React.useState( 0 );
  //const [ mosadname, setMosadname ] = React.useState( '' );
  const [ mosadName, setMosadname ] = React.useState( '' );
  const [ mosadSelected, setMosadSelected ] = React.useState( [] );
  const [ teacher, setTeacher ] = React.useState( 0 );
  const [ classRoom, setClassRoom ] = React.useState( 0 );
  const [ numClassRoom, setNumClassRoom ] = React.useState( 0 );
  const [ gender, setGender ] = React.useState( '' );
  const [ numMosadFilter, setNumMosadFilter ] = React.useState( 0 );

  const [ isValid, setIsValid ] = React.useState( false );

  const [ mosadList, setMosads ] = React.useState( [] );
  const [ teacherList, setTeacherList ] = React.useState( [] );
  const [ fileList, setFileList ] = React.useState( [] );
  const [ mosadsList, setMosadsList ] = React.useState( [] );
  const [ filteredMosadsList, setFilteredMosadsList ] = React.useState( [] );
  const [ schools_ids, setSchools_ids ] = React.useState( [] );
  const [ fileSrc, setFileSrc ] = React.useState( '' );
  const [ options, setOptions ] = React.useState( [] );
  const [ SnackbarOpen, setSnackbarOpen ] = React.useState( false );
  const [ statusCampaign, setStatusCampaign ] = React.useState( { severity: 'warning', title: '', content: '' } );

  const GetMosads = () => {
    apiService.User.Mosads.Get().then( result => {
      console.log( 'result.data', result.data )

      if ( result.status ) {

        const ListT = result.data.map( ( option ) => {
          const firstLetter = option.mosadname[ 0 ].toUpperCase();
          return {
            firstLetter: /[0-9]/.test( firstLetter ) ? '0-9' : firstLetter,
            ...option,
          };
        } );
        console.log( "Set options", ListT );
        setOptions( ListT );
        // setSchoolsSelected();

      }
    } );
  }
  const GetFiles = () => {

    apiService.User.Files.Get( mosad ).then( result => {
      console.log( 'result.data', result.data )
      if ( result.status ) {
        setFileList( result.data );
      }
    } );

  }
  const LoadSchools = () => {
    // if ( true || formState.values.schools.length ) {
    //   const schools = formState.values.schools.map( item => item.mosadpbx );
    //   console.log( 'formState.values.schools', schools )
    //todo! change API and endpoints to 'mosads'.
    apiService.User.Mosads.Get().then( result => {
      if ( result.status ) {
        setMosadsList( result.data );
        //filterStudents();
      }
    } );
    // }
  }
  const filterStudents = () => {


    if ( parseInt( numMosadFilter ) > 0 || parseInt( classRoom ) > 0 || parseInt( numClassRoom ) > 0 || mosadName || gender ) {
      const filtered = mosadsList.filter( item => {

        const mosadpbxFilter = parseInt( numMosadFilter ) > 0 ? item.mosadpbx === parseInt( numMosadFilter ) : true;
        const classFilter = parseInt( classRoom ) > 0 ? item.class_room === parseInt( classRoom ) : true;
        const subClassFilter = parseInt( numClassRoom ) > 0 ? item.sub_class_room === parseInt( numClassRoom ) : true;
        const mosadFilter = mosadName ? item.mosadname.includes( mosadName ) : true;
        const genderFilter = gender ? gender === '1' || gender === 'בנים' ? item.gender === 1 : gender === '2' || gender === 'בנות' ? item.gender === 2 : false : true;
        return mosadpbxFilter && classFilter && subClassFilter && mosadFilter && genderFilter;
        // if ( parseInt( numClassRoom ) > 0 )
        //   return item.class === parseInt( classRoom ) && item.numclass === parseInt( numClassRoom );
        // return item.class === parseInt( classRoom );
      } )
      setFilteredMosadsList( filtered )
    } else {
      setFilteredMosadsList( mosadsList )

    }
  }
  // const validateForm = () => {
  //
  //   // setIsValid( mosad && fileObject.size && selected ? true : false );
  //   setIsValid( formState.values.campaign_name && fileID && fileID !== 0 && mosad && selectedList.length ? true : false );
  // }

  const saveCampagin = () => {
    //1. save the main data + selected contacts.
    //2. upload file with new id
    const data = { ...formState.values, schools_ids };
    console.log( 'data', data );

    //return;
    if ( props.campaign ) {
      apiService.User.VoiceCampaigns.Update( props.campaign, data ).then( result => {
        console.log( 'result', result );
        // alert( 'קמפיין עודכן בהצלחה!' );
        setSnackbarOpen( true );
        //history.push( '../campaigns' )
      } );
    } else {
      apiService.User.VoiceCampaigns.Insert( data ).then( result => {
        //console.log( 'result', result );
//        alert( 'עודכן בהצלחה!' )
        setSnackbarOpen( true );
        history.push( './campaigns' )

      } );
    }
  }
  const publishCampaign = async () => {
    //alert( 'זמנית אינו פעיל! - ניתן לשמור בלבד' );
    const status = await apiService.User.VoiceCampaigns.Run( props.campaign );
    if ( status?.data?.status === 'success' ) {
      setFormState( formState => ( {
        ...formState,
        values: {
          ...formState.values,
          status: 1
        }
      } ) );
      history.push( `/app/campaigns/${ props.campaign }` )

      //formState.values.status
    }
    console.log( { status } )
  }
  const loadCampaign = () => {
    if ( props.campaign ) {
      apiService.User.VoiceCampaigns.Get( props.campaign ).then( result => {
        if ( result.status ) {

          const oldState = { ...formState };
          oldState.values = result.data;
          oldState.values.run_date = new Date( oldState.values.run_date ).toISOString().substr( 0, 10 )

          setSchools_ids( oldState.values.schools_ids );
          setFormState( oldState );

        } else {
          alert( 'ארעה שגיאה בהבאת נתוני קמפיין' )
        }

        // alert( 'load...' )
      } )

    }
  }
  const GetStatusCampaign = () => {
    let stt = {};
    switch ( formState.values.status ) {
      case 0:
        stt = { severity: 'warning', title: 'Draft', content: 'קמפיין זה נשמר. אך אינו מופעל' }
        break;
      case 1:
        stt = { severity: 'success', title: 'קמפיין פעיל', content: 'קמפיין זה מופעל וישודר בשעה שנקבעה' }
        break;
      case 10:
        stt = { severity: 'info', title: 'קמפיין הסתיים', content: 'קמפיין זה הסתיים' }
        break;
      case 3:
        stt = { severity: 'error', title: 'קמפיין נעצר', content: 'קמפיין זה נעצר ' }
        break;
      default:
        stt = { severity: 'info', title: 'קמפיין חדש', content: '' };
        break;
    }
    setStatusCampaign( stt );
  }
  const [ formState, setFormState ] = useState( {
    isValid: false,
    isValidDraft: false,
    values: {
      campaign_name: '',
      run_date: new Date().toISOString().substr( 0, 10 ),
      min_time: '08:00',
      max_time: '20:00',
      file_id: '0',
      // allSchools: false,
      // allStudents: true,
      //schools: [],//163022,
      allClasses: true,
      classesList: [],
    },
    touched: {},
    errors: {}
  } );

  useEffect( () => {
    //const errors = {};//validate( formState.values, schema );

    setFormState( formState => ( {
      ...formState,
      isValid: formState.values.campaign_name && formState.values.run_date && formState.values.min_time && formState.values.max_time && schools_ids && schools_ids.length,
      isValidDraft: formState.values.campaign_name && formState.values.file_id && formState.values.file_id != '0' ? true : false,
      errors: {}
    } ) );
  }, [ formState.values, schools_ids ] );
  const GetNameMosad = () => {
    alert( 0 )
    const m = mosadList.find( item => item.mosadpbx === formState.values.mosad_id );
    if ( m ) {
      setMosadname( m.mosadname );
      setMosadSelected( [ ...mosadList, m ] );
    }
  }
  const handleChange = event => {
    try {
      event.persist();
    } catch ( e ) {
    }

    setFormState( formState => ( {
      ...formState,
      values: {
        ...formState.values,
        [ event.target.name ]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [ event.target.name ]: true
      }
    } ) );
  };
  // useEffect( GetMosads, [] )
  useEffect( LoadSchools, [] )
  useEffect( filterStudents, [ classRoom, numClassRoom, numMosadFilter, mosadsList, mosadName, gender ] )
  // useEffect( validateForm, [ mosad, fileObject, selectedList, campaignName, fileID ] )
  useEffect( GetFiles, [] )
  useEffect( loadCampaign, [] )
  //useEffect( GetNameMosad, [ formState.values.mosad_id ] )
  useEffect( GetStatusCampaign, [ formState.values.status ] )


  const handleClassRoomChange = ( event ) => {
    setClassRoom( event.target.value );
  };
  const handleNumClassRoomChange = ( event ) => {
    setNumClassRoom( event.target.value );
  };
  const handleGenderChange = ( event ) => {
    setGender( event.target.value );
  };

  const handleNumMosadChange = ( event ) => {
    setNumMosadFilter( event.target.value );
  };
  const handleMosadNameChange = ( event ) => {
    setMosadname( event.target.value );
  };

  const handleClickUploadFile = () => {
    // if ( ! formState.values.mosad_id ) {
    //   alert( 'חובה לבחור מוסד' )
    // } else {
    setDialogStatus( true )
    //}
  }


  const [ personName, setPersonName ] = React.useState( [] );

  const handleChangePerson = ( event ) => {
      setPersonName( event.target.value );
    }
  ;

  const handleChangeMultiple = ( event ) => {
    const { options } = event.target;
    const value = [];
    for ( let i = 0, l = options.length; i < l; i += 1 ) {
      if ( options[ i ].selected ) {
        value.push( options[ i ].value );
      }
    }
    setPersonName( value );
  };
  const gotoCampaignStatus = () => {
    history.push( `/app/campaigns/${ props.campaign }` )
  }
  return (
    <div className={ classes.root }>

      <Grid container spacing={ 0 }>
        <Grid item sm={ 12 }>
          <Alert severity={ statusCampaign.severity } action={
            <Button disabled={ false } fullWidth variant="contained" color="secondary"
                    onClick={ formState.values.status === 0 ? publishCampaign : gotoCampaignStatus }>
              { formState.values.status === 0 ? 'הפעל קמפיין' : 'Show' }
            </Button>
          }>
            <AlertTitle>&nbsp;{ statusCampaign.title }</AlertTitle>

            { statusCampaign.content }

          </Alert>
        </Grid>
      </Grid>


      <Grid container spacing={ 0 }>
        <Grid item lg={ 6 } sm={ 12 }>
          <Card className={ classes.card }>
            <CardContent>
              <Typography className={ classes.title } color="textSecondary" gutterBottom>
                פרטי קמפיין
              </Typography>

              <TextField
                fullWidth
                value={ formState.values.campaign_name || '' }
                onChange={ handleChange }
                name="campaign_name"
                helperText="הזן שם פרוייקט"
                id="outlined-full-width"
                InputLabelProps={ {
                  shrink: true,
                } }
                label="שם הפרוייקט"
                margin="normal"
                placeholder="שם הפרוייקט"
                style={ { margin: 8 } }
                variant="outlined"
              />
              <TextField
                className={ classes.textField }
                fullWidth
                // value={ description }
                // onChange={ ( e ) => setDescription( e.target.value ) }
                value={ formState.values.description || '' }
                onChange={ handleChange }
                name="description"
                helperText="תיאור כללי של הקמפיין לנוחיותך"
                //defaultValue="Default Value"
                id="outlined-margin-none"
                InputLabelProps={ {
                  shrink: true,
                } }
                label="תיאור"
                placeholder={ 'תיאור' }
                style={ { margin: 8 } }
                variant="outlined"
              />

            </CardContent>
          </Card>
        </Grid>

        <Grid item lg={ 6 } sm={ 12 }>
          <Card className={ classes.card }>
            <CardContent>
              <Typography className={ classes.title } color="textSecondary" gutterBottom>
                בחר תזמון
              </Typography>

              <TextField
                className={ classes.textField }
                fullWidth
                helperText="תאריך שליחה"
                value={ formState.values.run_date }
                onChange={ handleChange }
                name="run_date"
                //defaultValue="Default Value"
                label="תאריך"
                margin="normal"
                placeholder={ 'תאריך' }
                type="date"
                variant="outlined"
                InputLabelProps={ {
                  shrink: true,
                } }
              />
              <Grid container spacing={ 2 }>
                <Grid item lg={ 6 } sm={ 12 }>
                  <TextField
                    className={ classes.textField }
                    fullWidth
                    helperText="החל משעה"
                    value={ formState.values.min_time || '' }
                    onChange={ handleChange }
                    name="min_time"
                    //defaultValue="Default Value"
                    label="החל משעה"
                    margin="normal"
                    placeholder={ 'בחר שעה' }
                    type="time"
                    variant="outlined"
                    InputLabelProps={ {
                      shrink: true,
                    } }
                  />
                </Grid>
                <Grid item lg={ 6 } sm={ 12 }>
                  <TextField
                    className={ classes.textField }
                    fullWidth
                    helperText="עד השעה"
                    value={ formState.values.max_time || '' }
                    onChange={ handleChange }
                    name="max_time"
                    //defaultValue="Default Value"
                    label="עד שעה"
                    margin="normal"
                    placeholder={ 'בחר שעה' }
                    type="time"
                    variant="outlined"
                    InputLabelProps={ {
                      shrink: true,
                    } }
                  />
                </Grid>
              </Grid>


            </CardContent>
          </Card>
        </Grid>
      </Grid>


      <Grid container spacing={ 0 }>
        <Grid item lg={ 6 } sm={ 12 }>
          <Card className={ classes.card }>
            <CardContent>
              <Typography className={ classes.title } color="textSecondary" gutterBottom>
                בחר או טען קובץ
              </Typography>

              <Grid container spacing={ 2 }>
                <Grid item lg={ 8 } sm={ 12 }>
                  <TextField
                    className={ classes.textField }
                    select
                    fullWidth
                    helperText="רשימת הקלטות"
                    // onChange={ selectFile }
                    // value={ fileID }
                    value={ formState.values.file_id || '' }
                    onChange={ handleChange }
                    name="file_id"
                    //defaultValue="Default Value"*/ }
                    label="הקלטות"
                    margin="normal"
                    defaultValue={ '0' }
                    placeholder={ 'מקבילה' }
                    variant="outlined"
                    InputLabelProps={ {
                      shrink: true
                    } }
                  >
                    <MenuItem
                      key='0'
                      value='0'>בחר או טען קובץ</MenuItem>
                    { fileList.map( ( option ) => (
                      <MenuItem key={ option.id } value={ option.id }>
                        { option.name }
                      </MenuItem>
                    ) ) }
                  </TextField>

                </Grid>

                <Grid item lg={ 4 } sm={ 12 }>
                  <Button style={ { marginTop: '21px' } } variant="contained" color="primary"
                          onClick={ handleClickUploadFile }>
                    טען קובץ...
                  </Button>
                </Grid>
              </Grid>
              <ReactAudioPlayer
                src={ formState.values.file_id ? `${ path_url }${ formState.values.file_id }` : '' }
                controls
              />


            </CardContent>
          </Card>
        </Grid>

        <Grid item lg={ 6 } sm={ 12 }>
          <Card className={ classes.card }>
            <CardContent>
              <Typography className={ classes.title } color="textSecondary" gutterBottom>
                מוסדות
              </Typography>
              <Grid container spacing={ 2 }>
                <Grid item lg={ 12 } sm={ 12 }>
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={ () => setDialogOpen( true ) }>
                    בחר מוסדות
                    ( { schools_ids.length } מוסדות נבחרו )
                  </Button>
                </Grid>
              </Grid>
              <Typography variant="h5" component="h2">
                { schools_ids.length } מוסדות נבחרו
              </Typography>
              <Grid container spacing={ 2 }>
                <Grid item lg={ 4 } sm={ 12 }>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={ formState.values.allClasses }
                        onChange={ handleChange }
                        name="allClasses"
                        color="primary"
                      />
                    }
                    label="כל הכיתות"
                  />
                </Grid>
                <Grid item lg={ 4 } sm={ 12 }>
                  <FormControl disabled={ formState.values.allClasses } style={ { minWidth: 250 } }
                               className={ classes.formControl }>
                    <InputLabel id="demo-mutiple-checkbox-label">סנן כיתות</InputLabel>
                    <Select
                      fullWidth
                      labelId="demo-mutiple-checkbox-label"
                      id="demo-mutiple-checkbox"
                      name='classesList'
                      multiple
                      value={ formState.values.classesList }
                      onChange={ handleChange }
                      input={ <Input/> }
                      renderValue={ ( selected ) => selected.join( ', ' ) }
                      MenuProps={ MenuProps }
                    >
                      { classesStrArr.map( ( name ) => (
                        <MenuItem key={ name } value={ name }>
                          <Checkbox checked={ formState.values.classesList.indexOf( name ) > -1 }/>
                          <ListItemText primary={ name }/>
                        </MenuItem>
                      ) ) }
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

      </Grid>


      <Grid container spacing={ 0 }>
        <Grid item lg={ 12 } sm={ 12 }>
          <Card className={ classes.card }>
            <CardContent>
              <Grid container spacing={ 2 }>
                <Grid item xs={ 6 }>
                  <Button disabled={ false && ! formState.isValidDraft } fullWidth variant="contained" color="primary"
                          onClick={ saveCampagin }>
                    שמור קמפיין (ללא הפעלה)
                  </Button>

                </Grid>
                <Grid item xs={ 6 }>
                  <Button fullWidth variant="contained" color="secondary"
                          onClick={ () => history.goBack() }>
                    ביטול
                  </Button>
                </Grid>
              </Grid>

            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={ 4 }
      >


        <Dialog

          open={ dialogOpen }
          scroll={ 'paper' }
          maxWidth={ 'lg' }
          onClose={ () => setDialogOpen( false ) }
          aria-labelledby="draggable-dialog-title"
        >
          <DialogTitle id="draggable-dialog-title">בחר מוסדות
            <Grid container spacing={ 2 }>
              <Grid item sm={ 6 }>


                <TextField
                  className={ classes.textField }
                  fullWidth
                  helperText="מוסד"
                  value={ mosadName }
                  onChange={ handleMosadNameChange }
                  //defaultValue="Default Value"
                  label="שם מוסד"
                  margin="normal"
                  placeholder={ ' מוסד' }
                  variant="outlined"
                  InputLabelProps={ {
                    shrink: true
                  } }
                />
              </Grid>
              <Grid item sm={ 3 }>


                <TextField
                  className={ classes.textField }
                  fullWidth
                  helperText="מוסד"
                  value={ numMosadFilter }
                  onChange={ handleNumMosadChange }
                  //defaultValue="Default Value"
                  inputProps={ { min: '0', max: '1000000', step: '1' } }
                  label="קוד מוסד"
                  margin="normal"
                  placeholder={ 'קוד מוסד' }
                  type="number"
                  variant="outlined"
                  InputLabelProps={ {
                    shrink: true
                  } }
                />
              </Grid>
              {/*<Grid item sm={ 2 }>*/ }
              {/*  <TextField*/ }
              {/*    className={ classes.textField }*/ }
              {/*    fullWidth*/ }
              {/*    helperText="כיתה"*/ }
              {/*    value={ classRoom }*/ }
              {/*    onChange={ handleClassRoomChange }*/ }
              {/*    inputProps={ { min: '0', max: '100', step: '1' } }*/ }
              {/*    //defaultValue="Default Value"*/ }
              {/*    label="כיתה"*/ }
              {/*    margin="normal"*/ }
              {/*    placeholder={ 'כיתה' }*/ }
              {/*    type="number"*/ }
              {/*    variant="outlined"*/ }
              {/*    InputLabelProps={ {*/ }
              {/*      shrink: true,*/ }
              {/*    } }*/ }
              {/*  />*/ }
              {/*</Grid>*/ }
              {/*<Grid item sm={ 2 }>*/ }
              {/*  <TextField*/ }
              {/*    className={ classes.textField }*/ }
              {/*    fullWidth*/ }
              {/*    helperText="כיתה מקבילה"*/ }
              {/*    value={ numClassRoom }*/ }
              {/*    onChange={ handleNumClassRoomChange }*/ }
              {/*    //defaultValue="Default Value"*/ }
              {/*    inputProps={ { min: '0', max: '10', step: '1' } }*/ }
              {/*    label="מקבילה"*/ }
              {/*    margin="normal"*/ }
              {/*    placeholder={ 'מקבילה' }*/ }
              {/*    type="number"*/ }
              {/*    variant="outlined"*/ }
              {/*    InputLabelProps={ {*/ }
              {/*      shrink: true*/ }
              {/*    } }*/ }
              {/*  />*/ }
              {/*</Grid>*/ }
              <Grid item sm={ 3 }>
                <TextField
                  className={ classes.textField }
                  fullWidth
                  helperText="מגדר"
                  value={ gender }
                  onChange={ handleGenderChange }
                  //defaultValue="Default Value"
                  label="מגדר"
                  margin="normal"
                  placeholder={ 'מגדר' }
                  variant="outlined"
                  InputLabelProps={ {
                    shrink: true
                  } }
                />
              </Grid>

            </Grid>
          </DialogTitle>
          <DialogContent>

            <ContactList data={ filteredMosadsList } selected={ schools_ids }
                         setParentList={ ( items ) => {
                           console.log( 'items selected', items );
                           setSchools_ids( items );
                         } }/>
          </DialogContent>
          <DialogActions>

            <Button
              color="primary"
              variant="contained"
              onClick={ () => setDialogOpen( false ) }
            >
              סגור
            </Button>
          </DialogActions>
        </Dialog>


      </Grid>


      {/*<RecordPage/>*/ }


      <DropzoneDialog
        acceptedFiles={ [ 'audio/*', 'audio/wav', 'audio/vnd.wav', 'audio/mpeg' ] }
        cancelButtonText={ 'cancel' }
        dropzoneText={ 'טען או גרור קובץ שמע ' }
        maxFileSize={ 5000000 }
        onAdd={ newFileObjs => {
          console.log( 'onAdd', newFileObjs );
          //setFileObjects([].concat(fileObjects, newFileObjs));
        } }
        onClose={ () => {
          setDialogStatus( false );

        } }
        onDelete={ deleteFileObj => {
          console.log( 'onDelete', deleteFileObj );
        } }
        onSave={ ( files ) => {
          console.log( 'Files:', files );
          // setFileObject( files[ 0 ] );
          apiService.User.Files.Upload( {
            name: files[ 0 ].name,
            //mosad_id: formState.values.mosad_id
          }, files[ 0 ] ).then( res => {
            const newList = [ ...fileList ];
            newList.push( { name: files[ 0 ].name, id: res.data.lastId } )
            setFileList( newList );
            handleChange( { target: { name: 'file_id', value: res.data.lastId } } );//321
          } );
          //handleClickOpen();
          setDialogStatus( false )
        } }
        open={ dialogStatus }
        showFileNamesInPreview
        showPreviews
        submitButtonText={ 'Save' }
      />
      <Snackbar anchorOrigin={ { vertical: 'top', horizontal: 'center' } }
                open={ SnackbarOpen } autoHideDuration={ 6000 } onClose={ () => setSnackbarOpen( false ) }>
        <Alert variant="filled" severity="success">
          הפרטים עודכנו בהצלחה!
        </Alert>
      </Snackbar>
    </div>
  );
}

import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';

import {
  Budget,
  TotalUsers,
  TasksProgress,
  TotalProfit,
  LatestSales,
  UsersByDevice,
  LatestProducts,
  LatestOrders
} from './components';
import apiService from '../../helpers/apiService';

const useStyles = makeStyles( theme => ( {
  root: {
    padding: theme.spacing( 4 )
  }
} ) );

const Dashboard = () => {
  const classes = useStyles();
  const [ widgets, setWidgets ] = useState( [
    { title: 'שיעורים חיים' },
    { title: 'הקלטות' },
    { title: 'מחוברים כעת' },
    { title: 'בשידור חי' },
  ] );
  const [ schoolsLive, setSchoolsLive ] = useState( [] );

  function getData() {
    apiService.User.Dashboard.UsageData().then( result => {
      const { data } = result;
      const Widgets = [
        {
          title: 'סה"כ שיעורים חיים',
          value: data.liveEver,
          extraValue: data.liveToday,
          extraTitle: 'חדשים מאתמול',
          icon: 'MoneyIcon'
        },
        {
          title: 'סה"כ הקלטות',
          value: data.recordEver,
          extraValue: data.recordsToday,
          extraTitle: 'חדשים מאתמול',
          icon: 'AttachMoneyIcon'
        },
        { title: 'תלמידים  מחוברים כעת', value: data.connected },
        { title: 'שיעורים חיים ברגע זה', value: data.confLive },
      ];
      setWidgets( Widgets );
      setSchoolsLive( data.schoolsLive );
      console.log( '----', result.data )
    } );
  }

  useEffect( getData, [] )


  return (
    <div className={ classes.root }>
      <Grid
        container
        spacing={ 4 }
      >
        { widgets.map( widget => (

          <Grid item lg={ 3 } sm={ 6 } xl={ 3 } xs={ 12 }>
            <TotalUsers widget={ widget }/>
          </Grid>

        ) ) }


        <Grid
          item
          lg={ 8 }
          md={ 12 }
          xl={ 9 }
          xs={ 12 }
        >
          {/*<LatestSales />*/ }
        </Grid>
        <Grid
          item
          lg={ 4 }
          md={ 6 }
          xl={ 3 }
          xs={ 12 }
        >
          {/*<UsersByDevice />*/ }
        </Grid>



        <Grid
          item
          lg={ 8 }
          md={ 12 }
          xl={ 9 }
          xs={ 12 }
        >
          <LatestOrders data={ schoolsLive }/>
        </Grid>
        <Grid
          item
          lg={ 4 }
          md={ 6 }
          xl={ 3 }
          xs={ 12 }
        >
          {/*<LatestProducts/>*/ }
        </Grid>
      </Grid>
    </div>
  );
};

export default Dashboard;

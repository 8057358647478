import React, { useState, useEffect } from 'react';
import MaterialTable from 'material-table'
import apiService from "../../../../helpers/apiService";


const UsersTable = props => {
  const history = props.history;
  let data;
  const [ users, setUsers ] = useState( [] );
  const [ mosads, setMosads ] = useState( { 12334: 'dddd', 465465: 'ddd' } );

  const [ values, setValues ] = useState( {

    columns: [
      { title: 'שם', field: 'name' },
      { title: 'שם משתמש', field: 'username' },
      { title: 'סיסמה', field: 'pwd' },
      //{ title: 'הרשאות', field: 'user_type' },
      {
        title: 'הרשאות',
        field: 'user_type',
        lookup: { 10: 'מנהל כללי', 2: 'מוסד' },
      },
      { title: 'מוסד pbx', field: 'mosad_id', lookup: mosads, editable: 'onAdd' },
      { title: 'מצב', field: 'active', lookup: { 1: 'פעיל', 0: 'חסום' } },
    ]
  } );

  function getUsers() {
    apiService.User.Users.Get().then( users => {
      setUsers( users.data );
    } );
  }

  function getMosads() {
    //apiService.User.Mosads.Get().then( mosads => {
    //   const obj_lookup = {};
    //   for ( let item of mosads.data ) {
    //
    //     obj_lookup[ item.mosadpbx ] = item.mosadname;
    //   }
    console.log( 'updpdpdpdpdpp' );
    setValues( {
      columns: [
        { title: 'שם', field: 'name' },
        { title: 'שם משתמש', field: 'username' },
        { title: 'סיסמה', field: 'pwd' },
        //{ title: 'הרשאות', field: 'user_type' },
        {
          title: 'הרשאות',
          field: 'user_type',
          lookup: { 10: 'מנהל כללי', 2: 'מוסד' },
        },
        { title: 'מוסד', field: 'mosadname', editable: 'onAdd' },
        // { title: 'מוסד', field: 'mosad_id', lookup: obj_lookup, editable: 'onAdd' },
        { title: 'מזהה טלפוניה', field: 'mosad_id', editable: 'never' },
        { title: 'מצב', field: 'active', lookup: { 1: 'פעיל', 0: 'חסום' } },
      ]
      // } )
      //setMosads( obj_lookup );
    } );
  }

  useEffect( getUsers, [] )
  useEffect( getMosads, [] )
  return (
    <MaterialTable
      title={ <h2>משתמשים והרשאות</h2> }
      columns={ values.columns }
      data={ users }
      localization={ { header: { actions: 'פעולות' } } }
      options={ {
        filtering: true,
        exportButton: true,
        sorting: true,
        exportAllData: true,
        pageSize: 20,
        addRowPosition: 'first'
      } }
      actions={ [
        {
          icon: 'edit',
          tooltip: 'ערוך יוזר',
          onClick: ( event, rowData ) => {
            console.log( 'ssss', rowData )
            history.push( `users/${ rowData.id }` );
          }
        },

        {
          icon: 'add',
          tooltip: 'Add User',
          isFreeAction: true,
          onClick: ( event ) => {
            history.push( `users/0` );
          }
        }
      ] }
      editable={ {
        // onRowAdd: newData =>
        //   new Promise( ( resolve, reject ) => {
        //     apiService.User.Users.Insert( newData ).then( result => {
        //       if ( result.status ) {
        //         let data = [ ...users ];
        //         data.unshift( newData );
        //         setUsers( data );
        //         resolve();
        //       } else {
        //         alert( 'ארעה שגיאה בהוספת רשומה. נא בדוק שכל הפרטים מולאו' );
        //         resolve();
        //       }
        //     } );
        //   } ),
        // onRowUpdate: ( newData, oldData ) =>
        //   new Promise( ( resolve, reject ) => {
        //     apiService.User.Users.Update( oldData.id, newData ).then( result => {
        //       if ( result.status ) {
        //         let data = [ ...users ];
        //         const index = data.findIndex( item => item.id === oldData.id );
        //         data[ index ] = newData;
        //         setUsers( data );
        //         resolve()
        //       } else {
        //         alert( 'Error...' );
        //         resolve()
        //       }
        //     } );
        //
        //   } ),
        onRowDelete: apiService.User.IsAdmin() ? ( oldData ) =>
          new Promise( ( resolve, reject ) => {
            apiService.User.Users.Delete( oldData.id ).then( result => {
              if ( result.status ) {
                let data = [ ...users ];
                const index = data.findIndex( item => item.id === oldData.id );
                data.splice( index, 1 );
                setUsers( data );
                resolve()
              } else {
                alert( 'Error...' );
                resolve()
              }
            } ).catch( e => {
              alert( 'ארעה שגיאה!' )
              resolve()

            } );

          } ) : undefined
      } }
    />
  )
};


export default UsersTable;

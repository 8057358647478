import React, { useState, useEffect } from 'react';
import MaterialTable from 'material-table'
import apiService from '../../../../helpers/apiService';


const UsersTable = props => {
  const [ isLoading, setIsLoading ] = useState( true );
  const [ values, setValues ] = useState( {

    columns: [
      { title: 'שם משפחה', field: 'last', editable: apiService.User.IsAdmin() ? 'always' : 'never' },
      { title: 'שם פרטי', field: 'first', editable: apiService.User.IsAdmin() ? 'always' : 'never' },
      {
        title: 'טלפון',
        field: 'phone',
        editable: apiService.User.IsAdmin() || apiService.User.IsManagerSchool() ? 'always' : 'never'
      },
      {
        title: 'טלפון 2',
        field: 'phone2',
        editable: apiService.User.IsAdmin() || apiService.User.IsManagerSchool() ? 'always' : 'never'
      },
      { title: 'זהות', field: 'id', editable: apiService.User.IsAdmin() ? 'always' : 'never' },
      { title: 'סך הקלטות', field: 'count_records', editable: apiService.User.IsAdmin() ? 'always' : 'never' },
      {
        title: 'מצב', field: 'active', lookup: { 1: 'פעיל', 0: 'חסום' },
        editable: apiService.User.IsAdmin() || apiService.User.IsManagerSchool() ? 'always' : 'never'
      },
    ]
  } );
  const [ teachers, setTeachers ] = useState( [] );

  function getTeachers() {
    apiService.User.Teachers.Get().then( teachers => {
      setTeachers( teachers.data );
      setIsLoading( false );
    } );
  }

  useEffect( getTeachers, [] )
  return (
    <MaterialTable
      columns={ values.columns }
      data={ teachers }
      localization={ { header: { actions: 'פעולות' } } }
      isLoading={ isLoading }
      editable={ {
        onRowAdd: apiService.User.IsAdmin() ? newData =>
          new Promise( ( resolve, reject ) => {
            setIsLoading( true );
            apiService.User.Teachers.Insert( newData ).then( result => {
              if ( result.status ) {
                let data = [ ...teachers ];
                newData.teacher_id = result.data.id;
                data.unshift( newData );
                setTeachers( data );
                resolve();
              } else {
                alert( 'ארעה שגיאה בהכנסת נתונים. נא בדוק שכל הפרטים הוזנו כראוי' );
                resolve();
              }
              setIsLoading( false );
            } );
          } ) : null,
        onRowUpdate: apiService.User.IsAdmin() || apiService.User.IsManagerSchool() ? ( newData, oldData ) =>
          new Promise( ( resolve, reject ) => {
            setIsLoading( true );
            apiService.User.Teachers.Update( oldData.teacher_id, newData ).then( result => {
              if ( result.status ) {
                let data = [ ...teachers ];
                const index = data.findIndex( item => item.teacher_id === oldData.teacher_id );
                data[ index ] = newData;
                setTeachers( data );
                resolve()
              } else {
                alert( 'ארעה שגיאה בעדכון נתונים. נא בדוק שכל הפרטים הוזנו כראוי' );
                resolve();
              }
              setIsLoading( false );
            } );

          } ) : null,
        // onRowDelete: ( oldData ) =>
        //   new Promise( ( resolve, reject ) => {
        //     apiService.User.Teachers.Delete( oldData.teacher_id ).then( result => {
        //       if ( result.status ) {
        //         let data = [ ...teachers ];
        //         const index = data.findIndex( item => item.teacher_id === oldData.teacher_id );
        //         data.splice( index, 1 );
        //         setTeachers( data );
        //         resolve()
        //       } else {
        //         alert( 'Error...' );
        //         resolve()
        //       }
        //     } ).catch( e => {
        //       alert( 'ארעה שגיאה!' )
        //       resolve();
        //     } );
        //
        //   } )
      } }
      options={ {
        filtering: true,
        exportButton: true,
        sorting: true,
        exportAllData: true,
        pageSize: 20,
        addRowPosition: 'first'
      } }
      title={ <h2>רשימת מורים</h2> }
      onRowClick={ ( e, rowData ) => {
        console.log( rowData );
        props.history.push( `./confs?teacher_id=${ rowData.teacher_id }` );
      } }
    />
  )
};


export default UsersTable;

import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Divider, Drawer } from '@material-ui/core';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PeopleIcon from '@material-ui/icons/People';
import BusinessIcon from '@material-ui/icons/Business';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import TextFieldsIcon from '@material-ui/icons/TextFields';
import ImageIcon from '@material-ui/icons/Image';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import SettingsIcon from '@material-ui/icons/Settings';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import SchoolIcon from '@material-ui/icons/School';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import PermPhoneMsgIcon from '@material-ui/icons/PermPhoneMsg';
import SettingsVoiceIcon from '@material-ui/icons/SettingsVoice';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import CreateIcon from '@material-ui/icons/Create';

import { Profile, SidebarNav, UpgradePlan } from './components';
import apiService from "../../../../helpers/apiService";
import { History, MeetingRoom } from "@material-ui/icons";

const useStyles = makeStyles( theme => ( {
  drawer: {
    width: 240,
    [ theme.breakpoints.up( 'lg' ) ]: {
      marginTop: 64,
      height: 'calc(100% - 64px)'
    }
  },
  root: {
    backgroundColor: theme.palette.white,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing( 2 )
  },
  divider: {
    margin: theme.spacing( 2, 0 )
  },
  nav: {
    marginBottom: theme.spacing( 2 )
  }
} ) );

const Sidebar = props => {
  const { open, variant, onClose, className, ...rest } = props;

  const classes = useStyles();

  const pages = [
    {
      title: 'מוסדות',
      href: '/app/mosads',
      icon: <BusinessIcon/>
    },
    {
      title: 'שיעורים חיים',
      href: '/app/confs',
      icon: < PermPhoneMsgIcon/>
    },
    {
      title: 'הקלטות',
      href: '/app/records',
      icon: < SettingsVoiceIcon/>
    },
    {
      title: 'הודעות ועדכונים',
      href: '/app/messages',
      icon: < SettingsVoiceIcon/>
    },

    // {
    //   title: 'הודעה קולית חדשה',
    //   href: '/app/create-message',
    //   icon: < SettingsVoiceIcon/>
    // }
  ];
  const pagesSchool = [
    {
      title: 'משתמשים',
      href: '/app/users',
      icon: <SupervisorAccountIcon/>
    },
    {
      title: 'מורים',
      href: '/app/teachers',
      icon: <SchoolIcon/>
    },
  ]
  const pages_admin = [
    {
      title: 'דשבורד',
      href: '/app/dashboard',
      icon: < DashboardIcon/>
    }
  ];
  if ( apiService.User.IsAdmin() ) {
    pages.unshift( ...pagesSchool );
    pages.unshift( ...pages_admin );
    pages.push( {
      title: 'לוג פעילות מורים',
      href: '/app/logsteachers',
      icon: < History/>
    } )
  } else if ( apiService.User.IsManagerSchool() ) {
    pages.unshift( ...pagesSchool );
  } else {//superviser
    pages.push( {
      title: 'מורים',
      href: '/app/teachers',
      icon: <SchoolIcon/>
    } );
  }
  //betbracha@gmail.com
  //BHI8998

  return (
    <Drawer
      anchor="left"
      classes={ { paper: classes.drawer } }
      onClose={ onClose }
      open={ open }
      variant={ variant }
    >
      <div
        { ...rest }
        className={ clsx( classes.root, className ) }
      >
        <Profile/>
        <Divider className={ classes.divider }/>
        <SidebarNav
          className={ classes.nav }
          pages={ pages }
        />
      </div>
    </Drawer>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired
};

export default Sidebar;

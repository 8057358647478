import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, Avatar, List, ListItem, ListItemAvatar, ListItemText, Button } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import TextField from "@material-ui/core/TextField";
import index from "../../../theme/overrides";
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import arrayMove from 'array-move';

import FolderIcon from '@material-ui/icons/Folder';
import DeleteIcon from '@material-ui/icons/Delete';
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import { LiveHelp, PlayCircleFilled, Edit } from "@material-ui/icons";
import Divider from "@material-ui/core/Divider";
import apiService from "../../../helpers/apiService";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";


const useStyles = makeStyles( ( theme ) => ( {
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: theme.spacing( 4 )

  },
  textField: {
    marginLeft: theme.spacing( 1 ),
    marginRight: theme.spacing( 1 )
  },
  card: {
    marginTop: theme.spacing( 1 ),
    marginRight: theme.spacing( 1 ),
    paddingLeft: theme.spacing( 1 ),
    paddingRight: theme.spacing( 1 ),
    cursor: 'pointer'
  },
  drag: {
    width: '100%',
    cursor: 'pointer'
  },
  demo: {
    backgroundColor: theme.palette.background.paper,
    width: '100%'

  },
  pointer: {
    cursor: 'pointer'
  },
  title: {
    margin: theme.spacing( 4, 0, 2 ),
  },
} ) );

export default function QuizEdit( props ) {
  const SortableItemOld = sortableElement( ( { value } ) => <Grid container spacing={ 0 }>
      <Grid item lg={ 12 } sm={ 12 }>
        <Card className={ classes.card }>
          <CardContent>
            <Typography className={ classes.title } color="textSecondary" gutterBottom>
              שאלה מס׳ { value }
            </Typography>

            <TextField

              value={ formState.values.name || '' }
              onChange={ handleChange }
              name="name"
              helperText="הזן תיאור השאלה"
              id="outlined-full-width"
              InputLabelProps={ {
                shrink: true,
              } }
              label="כותרת"
              margin="normal"
              placeholder="מה/מי/האם/מתי..."
              style={ { margin: 8 } }
              variant="outlined"
            />
            <TextField
              className={ classes.textField }
              type="number"
              value={ formState.values.description || '' }
              onChange={ handleChange }
              name="description"
              helperText="מספר התשובה הנכונה"
              id="outlined-margin-none"
              InputProps={ { inputProps: { min: 1, max: 10 } } }
              InputLabelProps={ {
                shrink: true,
              } }
              label="תשובה מספר"
              placeholder={ '1-9' }
              style={ { margin: 8 } }
              variant="outlined"
            />

            <TextField
              className={ classes.textField }
              type="number"
              value={ formState.values.score || '' }
              onChange={ handleChange }
              name="score"
              helperText=" מספר הנקודות על שאלה זו"
              id="outlined-margin-none"
              InputProps={ { inputProps: { min: 1, max: 10 } } }
              InputLabelProps={ {
                shrink: true,
              } }
              label="ניקוד עבור תשובה נכונה"
              placeholder={ '1-1000' }
              style={ { margin: 8 } }
              variant="outlined"
            />

            <Button variant="contained" color="secondary">השמע</Button>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
  const DragHandle = sortableHandle( () => <Avatar className={ classes.pointer }>
    <LiveHelp/>
  </Avatar> );

  const SortableContainer = sortableContainer( ( { children } ) => {
    console.log( "sort..." )
    return <Grid item xs={ 12 } md={ 12 }>
      <Typography variant="h6" className={ classes.title }>
        לעריכת השאלות לחץ על אייקון העריכה </Typography>
      { children }
    </Grid>
  } );
  const SortableItem = sortableElement( ( { value } ) => <Grid item xs={ 12 } md={ 6 }>
      <div className={ classes.demo }>
        <List dense={ false }>
          <ListItem>
            <ListItemAvatar>
              <DragHandle/>

            </ListItemAvatar>

            <ListItemText
              primary={ `שאלה מספר ${ value.index + 1 } - (${ value.value.score } נקודות)` }
              secondary={ value.value.title }
            />
            <ListItemSecondaryAction>


              <IconButton edge="end" aria-label="edit" onClick={ () => openDialog( value.value ) }>
                <Edit/>
              </IconButton>

              <IconButton edge="end" aria-label="delete">
                <DeleteIcon/>
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
          <Divider variant="inset" component="li"/>

        </List>
      </div>
    </Grid>
  );
  const { history } = props;
  const classes = useStyles();
  const [ formState, setFormState ] = useState( {
    isValid: false,
    isValidDraft: false,
    values: {
      campaign_name: '',
      date_start: new Date().toISOString().substr( 0, 10 ),
      min_time: '08:00',
      max_time: '20:00',
      //file_id: '0',
      allSchools: false,
      // allStudents: true,
      //schools: [],//163022,
      allClasses: true,
      classes_list: [],
    },
    touched: {},
    errors: {}
  } );
  // const [ items, setItems ] = useState( [ 1, 2, 3, 4, 5, 6 ] );
  const [ items, setItems ] = useState( [] );
  const [ quizId ] = React.useState( props?.match?.params?.id );
  const [ dialogStatus, setDialogStatus ] = useState( false );
  const [ questionSelected, setQuestionSelected ] = useState( 0 );

  function closeDialog() {
    setDialogStatus( false );
  }

  function openDialog( question ) {
    console.log( "true", question );
    if ( question ) {
      setQuestionSelected( question )
      setDialogStatus( true );
    }
  }

  const onSortEnd = ( { oldIndex, newIndex } ) => {
    console.log( { items } )
    const newitems = arrayMove( items, oldIndex, newIndex );
    console.log( { newitems } )
    setItems( newitems )
    // setItems( ( { items } ) => ( {
    //   items: arrayMove( items, oldIndex, newIndex ),
    // } ) );
    // this.setState(({items}) => ({
    //   items: arrayMove(items, oldIndex, newIndex),
    // }));
  };

  const loadDataQuestion = () => {

    if ( parseInt( quizId ) ) {

      apiService.User.Quiz.GetQuestions( quizId ).then( result => {
        if ( result.status ) {
          setItems( result.data )
          // const oldState = { ...formState };
          // oldState.values = result.data;
          // const dateO = oldState.values.date_start ? new Date( oldState.values.date_start ) : new Date()
          // oldState.values.date_start = dateO.toISOString().substr( 0, 10 )


        } else {
          alert( 'ארעה שגיאה בהבאת נתוני קמפיין' )
        }

      } )

    }
  }
  useEffect( loadDataQuestion, [] )
  const saveQuestion = () => {
    if ( questionSelected.id ) {
      const copyItems = [ ...items ];

      const item = copyItems.find( item => item.id === questionSelected.id );
      for ( let key of Object.keys( questionSelected ) ) {
        item[ key ] = questionSelected[ key ];
      }
      setItems( copyItems );
      apiService.User.Quiz.UpdateQuestion( quizId, questionSelected.id, questionSelected ).then( ( result ) => {
        console.log( { result } );
      } );
    } else {
      //questionSelected.id = items[ items.length - 1 ].id++;
      //setItems( [ ...items, questionSelected ] );
      apiService.User.Quiz.InsertQuestion( quizId, questionSelected ).then( ( result ) => {
        console.log( { result } );
        questionSelected.id = result.data?.id;
        setItems( [ ...items, questionSelected ] );
      } );
    }
    closeDialog();
  }
  const handleChange = event => {
    try {
      event.stopPropagation();
      event.persist();
    } catch ( e ) {
      console.log( "XXX" )
    }

    setQuestionSelected( { ...questionSelected, [ event.target.name ]: event.target.value } );
  };

  return (
    <div className={ classes.root }>
      <Grid container spacing={ 0 }>
        <Grid item lg={ 6 } sm={ 12 }>
          <div>ניתן לגרור את ״השאלות״ לשינוי סדר החידון ע״י לחיצה וגרירה על אייקון השאלה</div>
        </Grid>
      </Grid>

      <SortableContainer onSortEnd={ onSortEnd } useDragHandle>
        { items.map( ( value, index ) => (
          <SortableItem key={ `item-${ index }` } index={ index } value={ { value, index } }/>
        ) ) }
      </SortableContainer>

      <Divider/>

      <Button variant="contained" color="primary" onClick={ () => openDialog( {} ) }>הוסף שאלה חדשה</Button>
      <Dialog
        aria-labelledby="draggable-dialog-title"
        onClose={ closeDialog }
        open={ dialogStatus }
        fullWidth={ true }
        maxWidth={ 'md' }
      >
        <DialogTitle id="draggable-dialog-title"> שאלה #{ questionSelected.id || 'שאלה חדשה' }</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {/*Listing record: { singleRecord.id }. from { singleRecord.first } { singleRecord.last }.*/ }
            {/*(school: { singleRecord.mosadname })*/ }
            <Grid container spacing={ 0 }>
              <Grid item lg={ 12 } sm={ 12 }>
                <Card className={ classes.card }>
                  <CardContent>
                    <Typography className={ classes.title } color="textSecondary" gutterBottom>
                      ערוך את פרטי השאלה
                    </Typography>

                    <TextField

                      value={ questionSelected.title || '' }
                      onChange={ handleChange }
                      name="title"
                      helperText="הזן תיאור השאלה"
                      id="outlined-full-width"
                      InputLabelProps={ {
                        shrink: true,
                      } }
                      label="כותרת"
                      margin="normal"
                      placeholder="מה/מי/האם/מתי..."
                      style={ { margin: 8 } }
                      variant="outlined"
                    />
                    <TextField
                      className={ classes.textField }
                      type="number"
                      value={ questionSelected.current_answer || '' }
                      onChange={ handleChange }
                      name="current_answer"
                      helperText="מספר התשובה הנכונה"
                      id="outlined-margin-none"
                      InputProps={ { inputProps: { min: 1, max: 10 } } }
                      InputLabelProps={ {
                        shrink: true,
                      } }
                      label="תשובה מספר"
                      placeholder={ '1-9' }
                      style={ { margin: 8 } }
                      variant="outlined"
                    />

                    <TextField
                      className={ classes.textField }
                      type="number"
                      value={ questionSelected.score || '' }
                      onChange={ handleChange }
                      name="score"
                      helperText=" מספר הנקודות על שאלה זו"
                      id="outlined-margin-none"
                      InputProps={ { inputProps: { min: 1, max: 10 } } }
                      InputLabelProps={ {
                        shrink: true,
                      } }
                      label="ניקוד עבור תשובה נכונה"
                      placeholder={ '1-1000' }
                      style={ { margin: 8 } }
                      variant="outlined"
                    />

                    <audio
                      // autoPlay
                      controls
                      src={ `https://file-examples-com.github.io/uploads/2017/11/file_example_MP3_700KB.mp3` }
                    >
                      Your browser does not support the
                      <code>audio</code> element.
                    </audio>


                    <Button variant="contained" color="secondary" onClick={ saveQuestion }>שמור</Button>
                    <Button variant="contained" color="default" onClick={ closeDialog }>בטל</Button>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>

          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}

import React, { useState, useEffect } from 'react';
import MaterialTable from 'material-table'
import apiService from '../../../../helpers/apiService';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

const path_url = `${ apiService.API_DOMAIN }/records/play/`;
String.prototype.toHHMMSS = function () {
  var sec_num = parseInt( this, 10 ); // don't forget the second param
  var hours = Math.floor( sec_num / 3600 );
  var minutes = Math.floor( ( sec_num - ( hours * 3600 ) ) / 60 );
  var seconds = sec_num - ( hours * 3600 ) - ( minutes * 60 );

  if ( hours < 10 ) {
    hours = '0' + hours;
  }
  if ( minutes < 10 ) {
    minutes = '0' + minutes;
  }
  if ( seconds < 10 ) {
    seconds = '0' + seconds;
  }
  return hours + ':' + minutes + ':' + seconds;
}

const ClassesTable = props => {
  const [ values ] = useState( {

    columns: [
      //{ title: 'תאריך', field: 'stime', type: 'datetime', defaultSort: 'desc' },
      { title: 'שם מוסד', field: 'mosadname' },
      //{ title: 'עיר מוסד', field: 'mosadcity' },
      { title: 'סמל מוסד', field: 'mosadpbx' },
      // { title: 'מורה משפחה', field: 'last' },
      { title: 'מגדר', field: 'gender', lookup: { 1: 'בנים', 2: 'בנות' } },
      { title: 'כיתה', field: 'class_room', },
      { title: 'מקבילה', field: 'sub_class_room' },

    ]
  } );
  const [ records, setRecords ] = useState( [] );
  const [ dialogStatus, setDialogStatus ] = useState( false );
  const [ singleRecord, setSingleRecord ] = useState( {} );

  function closeDialog() {
    setDialogStatus( false );
  }

  function getClassrooms() {
    apiService.User.Classrooms.Get().then( records => {
      setRecords( records.data );
    } );
  }

  useEffect( getClassrooms, [] )
  return (
    <div>
      <MaterialTable
        columns={ values.columns }
        data={ records }
        localization={ { header: { actions: 'בחר' } } }
        options={ {
          filtering: true,
          // exportButton: true,
          isLoading: true,
          pageSize: 20,
          selection: true,
        } }
        onSelectionChange={ props.onChange }
        title={ <h4>בחר כיתות</h4> }
      />
      <Dialog
        aria-labelledby="draggable-dialog-title"
        onClose={ closeDialog }
        open={ dialogStatus }
      >
        <DialogTitle id="draggable-dialog-title">{ singleRecord.id } האזנה </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Listing record: { singleRecord.id }. from { singleRecord.first } { singleRecord.last }.
            (school: { singleRecord.mosadname })
            <h5>class</h5>
            <audio
              autoPlay
              controls
              src={ `${ path_url }${ singleRecord.conf }/${ singleRecord.mosadpbx }` }
            >
              Your browser does not support the
              <code>audio</code> element.
            </audio>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  )
};


export default ClassesTable;

import React from 'react';
import { Switch, Redirect } from 'react-router-dom';

import { RouteWithLayout } from './components';
import { Main as MainLayout, Minimal as MinimalLayout } from './layouts';
import {
  Dashboard as DashboardView,
  ProductList as ProductListView,
  UserList as UserListView,
  TeacherList as TeacherListView,
  MosadList as MosadListView,
  SupervisorsList as SupervisorsListView,
  StudentsList as StudentsListView,
  ConfsList as ConfsListView,
  RecordsList as RecordsListView,
  Icons as IconsView,
  Account as AccountView,
  Settings as SettingsView,
  SignUp as SignUpView,
  SignIn as SignInView,
  NotFound as NotFoundView
} from './views';
import apiService from "./helpers/apiService";
import HorizontalLabelPositionBelowStepper from "./views/VoiceCampaigns/CreateMessage";
import CampaignsList from "./views/VoiceCampaigns/CampaignsList";
import StudentForm from "./views/StudentsList/components/studentForm";
import UserForm from "./views/UserList/components/userForm";
import ClassRoomList from "./views/Classrooms";
import ActivityLogList from "./views/ActiviyLog";
import ActivityLogTeachersList from "./views/ActiviyLogTeachers";
import CampaignsStatus from "./views/VoiceCampaignsStatus/CampaignsStatus";
import MessagesList from "./views/MessagesList";
import QuizList from "./views/quiz/QuizList";
import QuizEdit from "./views/quiz/QuizEdit";
import QuizContent from "./views/quiz/quizContent";

const Routes = () => {
  return (
    <Switch>
      <Redirect
        exact
        from="/"
        to="/app/teachers"
      />
      <RouteWithLayout
        component={ DashboardView }
        exact
        layout={ MainLayout }
        path="/app/dashboard"
      />
      <RouteWithLayout
        hide={ ! apiService.User.IsAdmin() }
        component={ UserListView }
        exact
        layout={ MainLayout }
        path="/app/users"
      />
      <RouteWithLayout
        hide={ ! apiService.User.IsAdmin() }
        component={ UserForm }
        exact
        layout={ MainLayout }
        path="/app/users/:id"
      />;

      <RouteWithLayout
        component={ MosadListView }
        exact
        layout={ MainLayout }
        path="/app/mosads"
      />
      <RouteWithLayout
        component={ ClassRoomList }
        exact
        layout={ MainLayout }
        path="/app/classes"
      />
      <RouteWithLayout
        component={ TeacherListView }
        exact
        layout={ MainLayout }
        path="/app/teachers"
      />
      <RouteWithLayout
        component={ SupervisorsListView }
        exact
        layout={ MainLayout }
        path="/app/supervisors"
      />
      <RouteWithLayout
        component={ StudentsListView }
        exact
        layout={ MainLayout }
        path="/app/students"
      />
      <RouteWithLayout
        component={ StudentForm }
        exact
        layout={ MainLayout }
        path="/app/students/:id"
      />
      <RouteWithLayout
        component={ ConfsListView }
        exact
        layout={ MainLayout }
        path="/app/confs"
      />
      <RouteWithLayout
        component={ RecordsListView }
        exact
        layout={ MainLayout }
        path="/app/records"
      />
      <RouteWithLayout
        component={ MessagesList }
        exact
        layout={ MainLayout }
        path="/app/messages"
      />
      <RouteWithLayout
        component={ AccountView }
        exact
        layout={ MainLayout }
        path="/app/account"
      />
      <RouteWithLayout
        component={ SettingsView }
        exact
        layout={ MainLayout }
        path="/app/settings"
      />
      <RouteWithLayout
        component={ SignUpView }
        exact
        layout={ MinimalLayout }
        path="/app/sign-up"
      />
      <RouteWithLayout
        component={ SignInView }
        exact
        layout={ MinimalLayout }
        path="/app/sign-in"
      />
      <RouteWithLayout
        component={ HorizontalLabelPositionBelowStepper }
        exact
        layout={ MainLayout }
        path="/app/create-message"
      />
      <RouteWithLayout
        component={ HorizontalLabelPositionBelowStepper }
        exact
        layout={ MainLayout }
        path="/app/create-message/:campaign"
      />
      <RouteWithLayout
        component={ CampaignsList }
        exact
        layout={ MainLayout }
        path="/app/campaigns"
      />
      <RouteWithLayout
        component={ CampaignsStatus }
        exact
        layout={ MainLayout }
        path="/app/campaigns/:campaign"
      />
      <RouteWithLayout
        component={ ActivityLogList }
        exact
        layout={ MainLayout }
        path="/app/logs"
      />
      <RouteWithLayout
        component={ ActivityLogList }
        exact
        layout={ MainLayout }
        path="/app/logs/:student"
      />
      <RouteWithLayout
        component={ ActivityLogTeachersList }
        exact
        layout={ MainLayout }
        path="/app/logsteachers"
      />
      <RouteWithLayout
        component={ ActivityLogTeachersList }
        exact
        layout={ MainLayout }
        path="/app/logsteachers/:teacher"
      />
      <RouteWithLayout
        component={ QuizList }
        exact
        layout={ MainLayout }
        path="/app/quiz"
      />
      <RouteWithLayout
        component={ QuizEdit }
        exact
        layout={ MainLayout }
        path="/app/quiz/:id"
      />
      <RouteWithLayout
        component={ QuizContent }
        exact
        layout={ MainLayout }
        path="/app/quiz/content/:id"
      />
      <RouteWithLayout
        component={ NotFoundView }
        exact
        layout={ MinimalLayout }
        path="/app/not-found"
      />
      <Redirect to="/app/not-found"/>
    </Switch>
  );
};

export default Routes;

import { Grid } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import Button from "@material-ui/core/Button";
import AlertTitle from "@material-ui/lab/AlertTitle";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import MenuItem from "@material-ui/core/MenuItem";
import Collapse from "@material-ui/core/Collapse";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import ContactList from "../../VoiceCampaigns/CreateMessage/contactList";
import DialogActions from "@material-ui/core/DialogActions";
import { DropzoneDialog } from "material-ui-dropzone";
import apiService from "../../../helpers/apiService";
import Snackbar from "@material-ui/core/Snackbar";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import MosadClasses from "./mosadAndClass";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import Chip from "@material-ui/core/Chip";
import IconButton from "@material-ui/core/IconButton";
import { Delete } from "@material-ui/icons";
import { values } from "underscore";

const useStyles = makeStyles( ( theme ) => ( {
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: theme.spacing( 4 )

  },
  textField: {
    marginLeft: theme.spacing( 1 ),
    marginRight: theme.spacing( 1 )
  },
} ) );
const userTypes = [
  { id: 10, name: 'מנהל כללי' },
  { id: 5, name: 'מנהל מוסד' },
  { id: 2, name: 'מורה' },
  { id: 1, name: 'מפקח' },
]
export default function UserForm( props ) {
  const classes = useStyles();
  const { history } = props;
  const [ formState, setFormState ] = useState( {
    isValid: false,
    isValidDraft: false,
    values: {
      permissions: [ { mosad_id: 0, classes: '' } ],
      active: true,
      mosad_id: 0,
      //name: '',
      //permission_classes: '',
      //permission_mosads: '',
      pwd: '',
      user_type: apiService.User.IsAdmin() ? '' : 2,
      username: '',
    },
    touched: {},
    errors: {}
  } );
  const [ schools, setSchools ] = React.useState( [] );
  //const [ classList, setClassList ] = React.useState( [] );
  //const [ mosadPermissions, setMosadPermissions ] = React.useState( [] );
  //const [ permissionsObject, setPermissionsObject ] = React.useState( [] );
  const [ isNewItem, seIstNewItem ] = React.useState( true );
  const [ SnackbarOpen, setSnackbarOpen ] = React.useState( true );
  const [ title, setTitle ] = React.useState( props.match.params.id && props.match.params.id !== '0' ? `עריכת משתמש / ${ props.match.params.id }` : 'צור משתמש חדש' );

  const [ schools_ids, setSchools_ids ] = React.useState( [] );
  const [ dialogOpen, setDialogOpen ] = React.useState( false );
  const [ filteredMosadsList, setFilteredMosadsList ] = React.useState( [] );
  const [ mosadName, setMosadname ] = React.useState( '' );
  const [ numMosadFilter, setNumMosadFilter ] = React.useState( 0 );
  const [ gender, setGender ] = React.useState( '' );
  const [ classRoom, setClassRoom ] = React.useState( 0 );
  const [ numClassRoom, setNumClassRoom ] = React.useState( 0 );

  const filterMosads = () => {
    if ( parseInt( numMosadFilter ) > 0 || parseInt( classRoom ) > 0 || parseInt( numClassRoom ) > 0 || mosadName || gender ) {
      const filtered = schools.filter( item => {

        const mosadpbxFilter = parseInt( numMosadFilter ) > 0 ? item.mosadpbx === parseInt( numMosadFilter ) : true;
        const classFilter = parseInt( classRoom ) > 0 ? item.class_room === parseInt( classRoom ) : true;
        const subClassFilter = parseInt( numClassRoom ) > 0 ? item.sub_class_room === parseInt( numClassRoom ) : true;
        const mosadFilter = mosadName ? item.mosadname.includes( mosadName ) : true;
        const genderFilter = gender ? gender === '1' || gender === 'בנים' ? item.gender === 1 : gender === '2' || gender === 'בנות' ? item.gender === 2 : false : true;
        return mosadpbxFilter && classFilter && subClassFilter && mosadFilter && genderFilter;
        // if ( parseInt( numClassRoom ) > 0 )
        //   return item.class === parseInt( classRoom ) && item.numclass === parseInt( numClassRoom );
        // return item.class === parseInt( classRoom );
      } )
      setFilteredMosadsList( filtered )
    } else {
      setFilteredMosadsList( schools )

    }
  }
  const handleMosadNameChange = ( event ) => {
    setMosadname( event.target.value );
  };
  const handleNumMosadChange = ( event ) => {
    setNumMosadFilter( event.target.value );
  };
  const handleGenderChange = ( event ) => {
    setGender( event.target.value );
  };
  const checkIfValid = () => {
    if ( ! formState.values.name || ! formState.values.username || ! formState.values.pwd || ! formState.values.user_type || ! formState.values.permissions.length || formState.values.permissions[ 0 ].mosad_id === 0 ) {
      console.log( 'false valid' );

      setFormState( { ...formState, isValid: false } );
    } else {
      setFormState( { ...formState, isValid: true } );
    }
  }
  const handleChange = event => {
    try {
      event.persist();
    } catch ( e ) {
      // console.log( e );
    }

    setFormState( formState => ( {
      ...formState,
      values: {
        ...formState.values,
        [ event.target.name ]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [ event.target.name ]: true
      }
    } ) );
  };
  const GetMosads = () => {
    apiService.User.Mosads.Get().then( result => {
      console.log( 'result.data', result.data )

      if ( result.status ) {

        const ListT = result.data.map( ( option ) => {
          const firstLetter = option.mosadname[ 0 ].toUpperCase();
          return {
            firstLetter: /[0-9]/.test( firstLetter ) ? '0-9' : firstLetter,
            ...option,
          };
        } );
        console.log( "Set options", ListT );
        setSchools( ListT );
      }
    } );
  }
  const GetClasses = () => {
    // alert( formState.values.school ? formState.values.school.mosadpbx : 0 );
    if ( formState.values.school ) {
      apiService.User.Classrooms.Get( formState.values.school.mosadpbx ).then( result => {
        console.log( 'result.data', result.data );
        //setClassList( result.data );
      } );
    }
  }
  const GetUser = () => {
    if ( props.match.params.id ) {
      apiService.User.Users.Get( props.match.params.id ).then( result => {
        console.log( 'user', result );
        const stu = result.data;
        if ( result.status && stu ) {
          stu.active = stu.active === 1;
          seIstNewItem( false );
          console.log( 'stu', stu );
          const copyState = { ...formState };
          copyState.values = stu;
          setFormState( copyState );
          setSchools_ids( stu.permissions.map( val => val.mosad_id ) );
          // const per = stu.permission_mosads ? stu.permission_mosads.split( ',' ).map( item => {
          //   return { mosad_id: item, classes: stu.permission_classes.split( ',' ) };
          // } ) : [];
          // setPermissionsObject( per );
        }
      } )
    } else {
      seIstNewItem( true );
    }
  }
  const saveUser = () => {
    if ( ! isNewItem ) {
      apiService.User.Users.Update( props.match.params.id, {
        ...formState.values,
        //permissions: permissionsObject

      } ).then( result => {
        setSnackbarOpen( true );
      } );
    } else {
      apiService.User.Users.Insert( formState.values ).then( result => {
        setSnackbarOpen( true );
        history.push( '../users' );
      } );
    }
  }
  const addMosad = ( mosads ) => {
    const values = { ...formState.values };
    if ( mosads ) {
      values.permissions = mosads.map( val => ( { mosad_id: val, classes: '' } ) );
    } else {
      values.permissions = [ ...formState.values.permissions, { mosad_id: 0, classes: '' } ];
    }
    console.log( 'values.permissions', values.permissions )
    console.log( 'values.permissions', { ...formState, values } )
    setFormState( { ...formState, values } );
    //setPermissionsObject( [ ...permissionsObject, { mosad_id: 0 } ] )
  }
  const updateTitle = () => {
    setTitle( ! isNewItem ? `עריכת משתמש / ${ props.match.params.id }` : 'צור משתמש חדש' );
  }

  useEffect( GetMosads, [] )
  useEffect( GetUser, [] )
  useEffect( checkIfValid, [ formState.values ] )
  useEffect( updateTitle, [ isNewItem ] );
  useEffect( filterMosads, [ classRoom, numClassRoom, numMosadFilter,  mosadName, gender,schools ] )

  //useEffect( updateMosadAndClassPermisiions, [ formState.values.permission_mosads ] );
  //useEffect( () => console.log( "permissionsObject", permissionsObject ), [ permissionsObject ] );

  return (
    <div className={ classes.root }>
      <Grid container spacing={ 8 } alignItems={ 'center' }>
        <Grid item>
          <h1>{ title }</h1>
        </Grid>
      </Grid>

      <Grid container spacing={ 4 }>
        <Grid item sm={ 4 } xm={ 12 }>
          <FormControlLabel
            control={ <Switch name="active" checked={ formState.values.active } onChange={ handleChange }/> }
            label="פעיל"
          />
        </Grid>
      </Grid>

      <Grid container spacing={ 4 } alignItems={ 'center' }>
        <Grid item sm={ 3 } xs={ 12 }>
          <div>
            <TextField
              value={ formState.values.name || '' }
              disabled={ ! apiService.User.IsAdmin() && ! isNewItem }
              onChange={ handleChange }
              name="name"
              fullWidth
              helperText="שם"
              id="outlined-full-width"
              label="שם"
              margin="normal"
              placeholder="שם"
              style={ { margin: 8 } }
              variant="outlined"
              InputLabelProps={ {
                shrink: true,
              } }
            />
          </div>
        </Grid>
        <Grid item sm={ 3 } xs={ 12 }>
          <div>
            <TextField
              value={ formState.values.username || '' }
              onChange={ handleChange }
              name="username"
              helperText="שם משתמש"
              id="outlined-full-width"
              label="שם משתמש"
              margin="normal"
              placeholder="שם משתמש"
              style={ { margin: 8 } }
              variant="outlined"
              fullWidth
              InputLabelProps={ {
                shrink: true,
              } }
            />
          </div>
        </Grid>
        <Grid item sm={ 3 } xs={ 12 }>
          <div>
            <TextField
              value={ formState.values.pwd || '' }
              onChange={ handleChange }
              name="pwd"
              helperText="סיסמא"
              id="outlined-full-width"
              label="סיסמא"
              margin="normal"
              placeholder="סיסמא"
              style={ { margin: 8 } }
              variant="outlined"
              fullWidth
              InputLabelProps={ {
                shrink: true,
              } }
            />
          </div>
        </Grid>
      </Grid>

      <Grid container spacing={ 4 }>
        <Grid item sm={ 3 } xs={ 12 }>

          <FormControl variant="outlined" fullWidth className={ classes.formControl }>
            <InputLabel id="demo-mutiple-chip-label">סוג הרשאה</InputLabel>
            <Select
              disabled={ ! apiService.User.IsAdmin() }
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={ formState.values.user_type }
              onChange={ handleChange }
              name="user_type"
              label="סוג משתמש"
            >
              <MenuItem value="">
                <em>בחר...</em>
              </MenuItem>
              { userTypes.map( ( item ) => (
                <MenuItem key={ item.id } keyid={ item.id }
                          value={ item.id }>
                  { item.name }
                </MenuItem>
              ) ) }

            </Select>


          </FormControl>
        </Grid>
        <Grid item sm={ 4 } xs={ 12 }>

          <div>
            { ( () => {
              switch ( formState.values.user_type ) {
                case 10:
                  return 'הרשאות מלאות';
                case 5:
                  return 'הרשאות מלאות למוסד'
                case 2:
                  return 'גישה למורים תלמידים, הרשאות עריכה';
                case 1:
                  return 'צפייה בתלמידים והקלטות'
                case 0:
                  return 'צפייה בלבד במורים ובתלמידים'
                default:
                  return 'נא בחר סוג הרשאה'
              }
            } )() }
          </div>
        </Grid>
      </Grid>

      { formState.values.permissions.map( ( item, key ) => (
        <Grid key={ key } container spacing={ 4 }>
          <Grid item md={ 10 } sm={ 12 }>
            {/*<h2>{permissionsObject.length}: { item.mosad_id } - { key }</h2>*/ }
            <MosadClasses
              schools={ schools } item={ item } key_item={ key }
              onChange={ ( val ) => {
                // const item = permissionsObject[ key ];
                const item = formState.values.permissions[ key ];
                // if ( item ) {
                item.mosad_id = val.mosad_id;
                item.classes = val.classes;
                console.log( 'cl', item.classes )
                setFormState( {
                  ...formState,
                  values: { ...formState.values, permissions: formState.values.permissions }
                } )
                // } else {
                //   permissionsObject.push( val );
                // }
                //setPermissionsObject( permissionsObject )
              } }
              onRemove={ ( val ) => {
                console.log( key, val );
                const co_permissionsObject = { ...formState.values };

                co_permissionsObject.permissions.splice( val, 1 );
                console.log( co_permissionsObject );
                setFormState( { ...formState, values: co_permissionsObject } );

                //setPermissionsObject( co_permissionsObject );
              } }
            ></MosadClasses>
          </Grid>
        </Grid>

      ) ) }
      <p>&nbsp;</p>
      <Grid container spacing={ 4 }>
        <Grid item xs={ 4 }>
          <Button variant="contained" color="primary"
                  onClick={ addMosad }>
            הוסף מוסד
          </Button>


        </Grid>

        <Grid item xs={ 4 }>


          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={ () => setDialogOpen( true ) }>
            הוסף מוסדות מתוך רשימה
            ( { schools_ids.length } מוסדות נבחרו )
          </Button>
        </Grid>
      </Grid>
      <p>&nbsp;</p>

      <Grid container spacing={ 2 }>
        <Grid item xs={ 4 }>
          <Button

            fullWidth variant="contained" color="primary"
            disabled={ ! formState.isValid || ( ( ! apiService.User.IsAdmin() ) && formState.values.user_type !== 2 ) }
            onClick={ saveUser }>
            שמור
          </Button>
        </Grid>
        <Grid item xs={ 4 }>
          <Button fullWidth variant="contained" color="secondary"
                  onClick={ () => history.goBack() }>
            בטל
          </Button>
        </Grid>
      </Grid>


      {/*<RecordPage/>*/ }


      <Snackbar anchorOrigin={ { vertical: 'top', horizontal: 'center' } }
                open={ SnackbarOpen } autoHideDuration={ 6000 } onClose={ () => setSnackbarOpen( false ) }>
        <Alert variant="filled" severity="success">
          הפרטים עודכנו בהצלחה!
        </Alert>
      </Snackbar>


      <Dialog

        open={ dialogOpen }
        scroll={ 'paper' }
        maxWidth={ 'lg' }
        onClose={ () => setDialogOpen( false ) }
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle id="draggable-dialog-title">בחר מוסדות
          <Grid container spacing={ 2 }>
            <Grid item sm={ 6 }>


              <TextField
                className={ classes.textField }
                fullWidth
                helperText="מוסד"
                value={ mosadName }
                onChange={ handleMosadNameChange }
                //defaultValue="Default Value"
                label="שם מוסד"
                margin="normal"
                placeholder={ ' מוסד' }
                variant="outlined"
                InputLabelProps={ {
                  shrink: true
                } }
              />
            </Grid>
            <Grid item sm={ 3 }>


              <TextField
                className={ classes.textField }
                fullWidth
                helperText="מוסד"
                value={ numMosadFilter }
                onChange={ handleNumMosadChange }
                //defaultValue="Default Value"
                inputProps={ { min: '0', max: '1000000', step: '1' } }
                label="קוד מוסד"
                margin="normal"
                placeholder={ 'קוד מוסד' }
                type="number"
                variant="outlined"
                InputLabelProps={ {
                  shrink: true
                } }
              />
            </Grid>
            <Grid item sm={ 3 }>
              <TextField
                className={ classes.textField }
                fullWidth
                helperText="מגדר"
                value={ gender }
                onChange={ handleGenderChange }
                //defaultValue="Default Value"
                label="מגדר"
                margin="normal"
                placeholder={ 'מגדר' }
                variant="outlined"
                InputLabelProps={ {
                  shrink: true
                } }
              />
            </Grid>

          </Grid>
        </DialogTitle>
        <DialogContent>

          <ContactList data={ filteredMosadsList } selected={ schools_ids }
                       setParentList={ ( items ) => {
                         console.log( 'items selected', items );
                         setSchools_ids( items );
                         addMosad( items )
                       } }/>
        </DialogContent>
        <DialogActions>

          <Button
            color="primary"
            variant="contained"
            onClick={ () => setDialogOpen( false ) }
          >
            סגור
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

import React, { useState, useEffect } from 'react';
import MaterialTable from 'material-table'
import apiService from "../../../helpers/apiService";


const UsersTable = props => {
  const { history, data, columns, actions, options, onRowClick, title, editable } = props;
  const test = () => {
    //alert( '??' )
  }

  const [ values, setValues ] = useState( {

    columns: columns,
    actions: actions,
    onRowClick: onRowClick || test,
    options: options || {
      filtering: true,
      exportButton: true,
      sorting: true,
      exportAllData: true,
      pageSize: 20,
      rowStyle: rowData => ( {
        color: ( rowData.status === 10 ) ? 'rgb(116, 116, 116)' : ( rowData.status === 2 ? 'green' : '#000' )
      } )
    },
    editable: editable
  } );


  return (
    <MaterialTable
      title={ <h2>{ title }</h2> }
      columns={ values.columns }
      data={ data }
      options={ values.options }
      actions={ actions }
      onRowClick={ onRowClick }
      editable={ editable }
    />
  )
};


export default UsersTable;

import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';

import { UsersTable } from '../components/index';
import mockData from './data';
import apiService from '../../helpers/apiService';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { DropzoneArea, DropzoneDialog } from 'material-ui-dropzone';
import { Button } from "@material-ui/core";

const useStyles = makeStyles( theme => ( {
  root: {
    padding: theme.spacing( 3 )
  },
  content: {
    marginTop: theme.spacing( 2 )
  }
} ) );
const path_url = `${ apiService.API_DOMAIN }/records/playintro`;

const MosadList = ( props ) => {
  const { history } = props;
  const classes = useStyles();
  const tableProps = {
    columns: [
      { title: 'שם מוסד', field: 'mosadname' },
      { title: 'עיר', field: 'city' },
      { title: 'סמל מוסד', field: 'mosad', editable: 'onAdd' },
      { title: 'טלפון', field: 'phone' },
      {
        title: 'טלפון מערכת', field: 'did',
        // render: rowData => rowData.did && rowData.did !== '-' ? '0' + rowData.did.toString().substr( 6, rowData.did.toString().length ) : '-'
      },
      {
        title: 'מזהה טלפוניה', field: 'mosadpbx', editable: 'onAdd'
      },//'always' 'never' 'onUpdate' 'onAdd'
      { title: 'מגדר', field: 'gender', lookup: { 1: 'בנים', 2: 'בנות' } },
      //{ title: 'כמות תלמידים0', field: 'numstudent' },
      { title: 'כמות תלמידים', field: 'countStudents', editable: 'never' },
      // { title: 'שם משתמש', field: 'mosadpbx' },
      // { title: 'סיסמה', field: 'pass' },
      //{ title: 'פעיל', field: 'active' },
      { title: 'מצב', field: 'active', lookup: { 1: 'פעיל', 0: 'חסום' } },
      { title: 'פתיח אישי', field: 'introFile', lookup: { 1: 'מופעל', 0: 'ללא' } },
    ],
    actions: [
      {
        icon: '♫',
        tooltip: 'פתיח אישי',
        onClick: ( event, rowData ) => {
          setSingleRecord( rowData );
          if ( rowData.introFile ) {
            setDialogStatus( true );
          } else {
            setDialogUploadStatus( true )
          }
        }
      }
    ],
    options: {
      filtering: true,
      exportButton: true,
      sorting: true,
      exportAllData: true,
      pageSize: 20,
      addRowPosition: 'first'
    },
    editable: {
      onRowAdd: newData =>
        new Promise( ( resolve, reject ) => {
          apiService.User.Mosads.Insert( newData ).then( result => {
            if ( result.status ) {
              let data = [ ...mosads ];
              data.unshift( newData );
              setMosads( data );
              resolve();
            } else {
              alert( 'ארעה שגיאה בהוספת רשומה. נא בדוק שכל הפרטים מולאו' );
              resolve();
            }
          } );
        } ),
      onRowUpdate: apiService.User.IsAdmin() ? ( newData, oldData ) =>
        new Promise( ( resolve, reject ) => {
          apiService.User.Mosads.Update( oldData.mosadpbx, newData ).then( result => {
            if ( result.status ) {
              let data = [ ...mosads ];
              const index = data.findIndex( item => item.mosad === oldData.mosad );
              data[ index ] = newData;
              setMosads( data );
              resolve()
            } else {
              alert( 'ארעה שגיאה בעדכון נתונים. נא בדוק שכל הפרטים הוזנו כראוי' );
              resolve()
            }
          } );

        } ) : undefined
    },
    onRowClick: ( e, rowData ) => {
      history.push( `./confs?mosad=${ rowData.mosad }` );
    }
  }

  const [ users ] = useState( mockData );
  const [ mosads, setMosads ] = useState( [] );
  const [ dialogStatus, setDialogStatus ] = useState( false );
  const [ dialogUploadStatus, setDialogUploadStatus ] = useState( false );
  const [ singleRecord, setSingleRecord ] = useState( {} );
  const [ refresh, setRefresh ] = useState( 0 );

  function getMosads() {
    apiService.User.Mosads.Get().then( mosads => {
      setMosads( mosads.data );
    } );
  }

  const closeDialog = () => {
    setDialogStatus( false )
  }
  useEffect( getMosads, [ refresh ] )
  return (
    <div className={ classes.root }>

      <div className={ classes.content }>

        <UsersTable
          actions={ tableProps.actions }
          columns={ tableProps.columns }
          data={ mosads }
          editable={ tableProps.editable }
          history={ props.history }
          onRowClick={ tableProps.onRowClick }
          options={ tableProps.options }
          title="רשימת מוסדות"
        />
      </div>
      <div>
        <Dialog
          aria-labelledby="draggable-dialog-title"
          onClose={ closeDialog }
          open={ dialogStatus }
        >
          <DialogTitle id="draggable-dialog-title">{ singleRecord.id } האזנה </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Listing record: { singleRecord.id }. from { singleRecord.first } { singleRecord.last }.
              (school: { singleRecord.mosadname })
              {/*<h5>Click to play</h5>*/ }
              <audio
                autoPlay
                controls
                src={ `${ path_url }/${ singleRecord.mosadpbx }` }
              >
                Your browser does not support the
                <code>audio</code> element.
              </audio>


            </DialogContentText>

            <Button variant="contained" color="primary" onClick={ e => {
              setDialogUploadStatus( true )
              setDialogStatus( false )
            } }>
              טען/החלף קובץ
            </Button>&nbsp;
            <Button variant="contained" color="warning" onClick={ e => {
              apiService.User.Mosads.RemoveIntro( singleRecord.mosadpbx ).then( e => {
                setRefresh( refresh + 1 );
                setDialogStatus( false )
                alert( 'הפתיח נמחק' )
              } )
            } }>
              מחק פתיח אישי
            </Button>
          </DialogContent>
        </Dialog>
      </div>

      <div>
        <DropzoneDialog
          acceptedFiles={ [ 'audio/*', 'audio/wav', 'audio/vnd.wav', 'audio/mpeg' ] }
          cancelButtonText={ 'cancel' }
          dropzoneText={ 'גרור לכאן קובץ חדש  - קובץ שמע בלבד' }
          maxFileSize={ 100000000 }

          onClose={ () => {
            setDialogUploadStatus( false );
          } }
          onSave={ ( files ) => {
            console.log( 'Files:', files );
            setDialogUploadStatus( false );
            apiService.User.Mosads.Upload( singleRecord, files[ 0 ], true ).then( res => {
              console.log( 'res', res );
              alert( 'העלאת הקובץ הושלמה' )
              setRefresh( refresh + 1 );
              setDialogUploadStatus( false );
            } );

          } }
          open={ dialogUploadStatus }
          showFileNamesInPreview
          showPreviews
          submitButtonText={ 'Save' }
        />
      </div>
    </div>
  );
};

export default MosadList;

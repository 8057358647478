import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';

import { UsersTable } from './components';
import mockData from './data';
import queryStringParse from '../../helpers/queryStringParse';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles( theme => ( {
  root: {
    padding: theme.spacing( 3 )
  },
  content: {
    marginTop: theme.spacing( 2 )
  }
} ) );

const UserList = ( props ) => {
  const classes = useStyles();

  const [ users ] = useState( mockData );

  return (
    <div className={ classes.root }>
      <Button variant="contained" color="primary" href={ `/app/records${ props.location.search }` }>
        עבור להקלטות
      </Button>
      <div className={ classes.content }>
        <UsersTable users={ users } filter={ queryStringParse( props.location ) }/>
      </div>
    </div>
  );
};

export default UserList;

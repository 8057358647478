import React, { useState, useEffect } from 'react';
import MaterialTable from 'material-table'
import apiService from "../../../../helpers/apiService";


const UsersTable = props => {
  const [ values, setValues ] = useState( {

    columns: [
      { title: 'מספר ועידה', field: 'id' },
      { title: 'שם מוסד', field: 'mosadname' },
      { title: 'עיר מוסד', field: 'mosadcity' },
      { title: 'סמל מוסד', field: 'mosad', defaultFilter: props.filter.mosad },
      { title: 'כיתה', field: 'class' },
      { title: 'מקבילה', field: 'numclass' },
      {
        title: 'מורה',
        field: 'teacher_id',
        defaultFilter: props.filter.teacher_id,
        render:rowData=>`${rowData.last} ${rowData.first}`,
        customFilterAndSearch: ( ( filterStr, rowData ) => {
          return rowData.teacher_id === parseInt( filterStr )||rowData.last.indexOf(filterStr)>=0||rowData.first.indexOf(filterStr)>=0
        } )
      },
      // { title: 'מורה משפחה', field: 'last' },
      // { title: 'מורה פרטי', field: 'first' },
      { title: 'שעת התחלה', field: 'stime' },
      { title: 'משתתפים', field: 'count' },
    ]
  } );
  const [ data, setData ] = useState( [] );


  function getData() {
    apiService.User.Confs.Get().then( result => {
      setData( result.data );
      console.log( "----", result.data[ 0 ] )
    } );
  }

  useEffect( getData, [] )
  return (
    <MaterialTable
      title={<h2>שיעורים חיים</h2>}
      columns={ values.columns }
      data={ data }
      options={ {
        filtering: true,
        exportButton: true,
        isLoading: true,
        pageSize: 20,
      } }
    />
  )
};


export default UsersTable;

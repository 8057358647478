import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import Chip from "@material-ui/core/Chip";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import apiService from "../../../helpers/apiService";
import { Grid } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import { Delete, PhotoCamera } from "@material-ui/icons";
import Button from "@material-ui/core/Button";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Collapse from "@material-ui/core/Collapse";

const useStyles = makeStyles( ( theme ) => ( {
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: theme.spacing( 4 )

  },
  textField: {
    marginLeft: theme.spacing( 1 ),
    marginRight: theme.spacing( 1 )
  },
} ) );

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function MosadClasses( props ) {
  const classes = useStyles();
  const [ schools, setSchools ] = React.useState( [] );
  const [ classroomsSelected, setClassroomsSelected ] = React.useState( [] );
  const [ classroomsList, setClassroomsList ] = React.useState( [] );
  const [ itemSelected, setItemSelected ] = React.useState( '' );
  const [ classLimited, setClassLimited ] = React.useState( props.item.classes && props.item.classes.length ? true : false );
  const [ mosadAndClasses, setMosadAndClasses ] = React.useState( props.item );
  const handleChangeClass = ( event, value ) => {
    console.log( 'v', event.target, value );
    const oldClasses = mosadAndClasses.classes.split( ',' );
    setClassroomsSelected( event.target.value );
    const indexItem = oldClasses.indexOf( value.props.keyid.toString() );

    if ( indexItem == -1 ) {
      oldClasses.push( value.props.keyid );
    } else {
      oldClasses.splice( indexItem, 1 );
    }
    // setClassroomsSelected( [ ...classroomsSelected, value.props.value ] );
    // const classes = [ ...mosadAndClasses.classes.split( ',' ), value.props.keyid ].toString();
    setMosadAndClasses( { ...mosadAndClasses, classes: oldClasses.toString() } );
  }
  // useEffect( () => setSchools( props.schools ), [ props.schools ] )
  const GetClasses = () => {
    // alert( formState.values.school ? formState.values.school.mosadpbx : 0 );
    if ( itemSelected && itemSelected.mosadpbx ) {
      apiService.User.Classrooms.Get( itemSelected.mosadpbx ).then( result => {
        console.log( 'result.data', result.data );
        setClassroomsList( result.data );
      } );
    }
  }
  const updateChange = () => {
    if ( props.onChange ) {
      props.onChange( mosadAndClasses )
    }
  }
  const getMosadItemById = () => {
    const item = props.schools.find( val => val.mosadpbx === parseInt( props.item.mosad_id ) );

    if ( item ) {
      setItemSelected( item );
    }
  }
  const selectClassesByProps = () => {
    //classroomsSelected
    if ( classroomsList.length ) {
      let classes = props.item.classes;
      //console.log( 'item', props.item )
      if ( classes ) {
        classes = classes.split( ',' );
        console.log( 'classes', classes )

        let items = classroomsList.filter( val => classes.indexOf( val.id.toString() ) !== -1 );
        items = items.map( val => `${ val.class_room }/ (${ val.sub_class_room })` );
        setClassroomsSelected( items );
      }
    }
  }
  useEffect( GetClasses, [ itemSelected ] )
  useEffect( selectClassesByProps, [ classroomsList ] )
  useEffect( updateChange, [ mosadAndClasses ] )
  useEffect( getMosadItemById, [ props.schools, props.item ] )


  return (
    <div className={ classes.root }>
      <Grid container spacing={ 4 }>
        <Grid item xm={ 2 }>

          <IconButton color="primary" aria-label="upload picture" size={ 'small' } component="span" onClick={ () => {
            if ( props.onRemove ) {
              props.onRemove( props.key_item );
            }
          } }>
            <Delete/>
          </IconButton>

        </Grid>
        <Grid item sm={ 4 } xs={ 12 }>

          <Autocomplete
            style={ { width: '100%' } }
            id="teacher_selected"
            options={ props.schools.sort( ( a, b ) => -b.firstLetter.localeCompare( a.firstLetter ) ) }
            getOptionLabel={ ( option ) => option.mosadname ? `${ option.mosadname } ${ option.gender ? `(${ option.gender === 1 ? 'בנים' : 'בנות' })` : '' }` : '' }
            autoSelect
            fullWidth
            openOnFocus
            multiple={ false }
            groupBy={ ( option ) => option.firstLetter }
            value={ itemSelected }
            onChange={ ( e, value ) => {
              console.log( 'value', value )
              setItemSelected( value );
              if ( value )
                setMosadAndClasses( { mosad_id: value.mosadpbx, classes: '' } );

              setClassroomsSelected( [] );
              // if ( props.onChange ) {
              //   props.onChange( mosadAndClasses )
              // }
            } }

            renderInput={ ( params ) => <TextField
              { ...params }
              fullWidth
              label="בחר מוסד"
              className={ classes.textField }
              variant="outlined"
            /> }

          />

        </Grid>
        <Grid item sm={ 2 } xs={ 12 }>
          <FormControlLabel variant="outlined" className={ classes.formControl }
                            control={ <Switch name="active" checked={ classLimited }
                                              onChange={ ( e, val ) => {
                                                setClassLimited( val );
                                                if ( ! val ) {
                                                  setMosadAndClasses( { ...mosadAndClasses, classes: '' } );
                                                  setClassroomsSelected([] );
                                                }
                                              } }/> }
                            label="הגבל כיתות ">

          </FormControlLabel>

        </Grid>
        <Grid item sm={ 4 } xs={ 12 }>
          <Collapse in={ classLimited }>
            <FormControl fullWidth variant="outlined" disabled={ ! itemSelected } className={ classes.formControl }>
              <InputLabel id="demo-mutiple-chip-label">בחר כיתות מורשות</InputLabel>
              <Select style={ { width: '100%' } } labelId="demo-mutiple-chip-label"
                      id="demo-mutiple-chip"
                      multiple
                      fullwidth
                      variant="outlined"
                      value={ classroomsSelected }
                      onChange={ handleChangeClass }
                      input={ <Input variant="outlined" id="select-multiple-chip"/> }
                      renderValue={ ( selected ) => (
                        <div className={ classes.chips }>
                          { selected.map( ( value ) => (
                            <Chip key={ value } label={ value } className={ classes.chip }/>
                          ) ) }
                        </div>
                      ) }
                      MenuProps={ MenuProps }
              >
                { classroomsList.map( ( item ) => (
                  <MenuItem key={ item.id } keyid={ item.id }
                            value={ `${ item.class_room }/ (${ item.sub_class_room })` }>
                    כיתה { item.class_room }/ ({ item.sub_class_room })
                  </MenuItem>
                ) ) }
              </Select>
            </FormControl>
          </Collapse>
        </Grid>
      </Grid>
    </div>
  );
}

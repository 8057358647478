import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';

import { UsersTable } from '../components';
import Button from "@material-ui/core/Button";
import apiService from "../../helpers/apiService";

const useStyles = makeStyles( theme => ( {
  root: {
    padding: theme.spacing( 3 )
  },
  content: {
    marginTop: theme.spacing( 2 )
  }
} ) );


const CampaignsStatus = ( props ) => {
  const classes = useStyles();
  const { history } = props;


  const loadCampaign = () => {
    console.log( { props } )
    if ( props?.match?.params?.campaign ) {
      apiService.User.VoiceCampaigns.Get( props?.match?.params?.campaign ).then( result => {
        if ( result.status ) {

          const newValues=result.data;
          newValues.run_date = new Date( newValues.run_date ).toISOString().substr( 0, 10 )
          console.log( { newValues } )

          setFormState( formState => ( {
            ...formState,
            values:{
              ...formState.values,
              ...newValues
            }
          } ) );

        } else {
          alert( 'ארעה שגיאה בהבאת נתוני קמפיין' )
        }

        // alert( 'load...' )
      } )

    }
  }
  const loadContacts = () => {
    if ( props?.match?.params?.campaign ) {
      apiService.User.VoiceCampaigns.GetContacts(  props?.match?.params?.campaign ).then( result => {
        console.log( 'contacts', result )

        if ( result.status ) {
          const contacts = result.data;
          setFormState( formState => ( {
            ...formState,
            contacts
          } ) );
        } else {
          alert( 'ארעה שגיאה בהבאת אנשי קשר' )
        }
      } )
    }
  }
  const [ formState, setFormState ] = useState( {
    campaign_id: props?.match?.params?.campaign,
    isValid: false,
    isValidDraft: false,
    values: {
      campaign_name: '',
      run_date: new Date().toISOString().substr( 0, 10 ),
      min_time: '08:00',
      max_time: '20:00',
      file_id: '0',
      // allSchools: false,
      // allStudents: true,
      //schools: [],//163022,
      allClasses: true,
      classesList: [],
    },
    touched: {},
    errors: {}
  } );
  const columns = [
    { title: 'מזהה', field: 'id', defaultSort: 'desc' },
    { title: 'שם פרטי', field: 'contact_firstname' },
    { title: 'שם משפחה', field: 'contact_lastname' },
    { title: 'טלפון אמא', field: 'contact_phone_mo' },
    { title: 'טלפון אבא', field: 'contact_phone_fa' },
    { title: 'טלפון', field: 'contact_phone' },
    {
      title: 'מצב',
      field: 'status',
      lookup: { 0: 'לא נשלח', 1: 'מחייג', 2: 'בשיחה', 3: 'נכשל', 10: 'הסתיים' },
    },
    { title: 'תאריך אחרון', field: 'updated_at', type: 'datetime' },
    { title: 'סך נסיונות', field: 'counter' }
  ];
  const statusToString = () => {
    switch ( formState.values.status ) {
      case 0:
        return 'נשמר';
      case 1:
        return `הופעל. יישלח ב ${ formState.values.run_date }
        בין השעות ${ formState.values.min_time } ל ${ formState.values.max_time }`
      case 2:
        return 'מכין נתונים';
      case 3:
        return 'בהרצה כעת';
      case 4:
        return 'עצר';
      case 9:
        return 'נכשל';
      case 10:
        return 'הסתים';
    }
  }
  const stopCampaign = async () => {
    //alert( 'זמנית אינו פעיל! - ניתן לשמור בלבד' );
    const status = await apiService.User.VoiceCampaigns.Stop( formState.campaign_id );
    if ( status?.data?.status === 'success' ) {
      setFormState( formState => ( {
        ...formState,
        values: {
          ...formState.values,
          status: 0
        }
      } ) );
      history.push( `/app/create-message/${ formState.campaign_id }` )
      //formState.values.status
    }
    console.log( { status } );
  }

  useEffect( loadCampaign, [] )
  useEffect( loadContacts, [] )
  return (
    <div className={ classes.root }>
      <h3> סטטוס קמפיין
        ״{ formState.values.campaign_name }״
        - { statusToString() }</h3>
      <Button disabled={ formState.values.status > 1 } variant="contained" color="primary"
              onClick={ stopCampaign }>
        עצור - וחזור לעריכה
      </Button>

      <Button variant="contained" color="primary"
              onClick={ () => history.goBack() }>
        חזור
      </Button>

      <div className={ classes.content }>
        <UsersTable history={ props.history } columns={ columns } data={ formState.contacts }/>
      </div>


    </div>
  );
};

export default CampaignsStatus;

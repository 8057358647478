import { Grid } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import Button from "@material-ui/core/Button";
import AlertTitle from "@material-ui/lab/AlertTitle";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import MenuItem from "@material-ui/core/MenuItem";
import Collapse from "@material-ui/core/Collapse";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import ContactList from "../../VoiceCampaigns/CreateMessage/contactList";
import DialogActions from "@material-ui/core/DialogActions";
import { DropzoneDialog } from "material-ui-dropzone";
import apiService from "../../../helpers/apiService";
import Snackbar from "@material-ui/core/Snackbar";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import mosadAndClass from "../../UserList/components/mosadAndClass";

const useStyles = makeStyles( ( theme ) => ( {
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: theme.spacing( 4 )

  },
  textField: {
    marginLeft: theme.spacing( 1 ),
    marginRight: theme.spacing( 1 )
  },
} ) );

export default function StudentForm( props ) {
  const classes = useStyles();
  const { history } = props;
  const [ formState, setFormState ] = useState( {
    isValid: false,
    isValidDraft: false,
    values: {
      first: '',
      last: '',
      mosadpbx: 0,
      phone: '',
      phone2: '',
      phone_fa: '',
      phone_fa2: '',
      school: 0,//163022
      active: true
    },
    touched: {},
    errors: {}
  } );
  const [ schools, setSchools ] = React.useState( [] );
  const [ classList, setClassList ] = React.useState( [] );
  const [ isNewItem, seIstNewItem ] = React.useState( true );
  const [ SnackbarOpen, setSnackbarOpen ] = React.useState( false );
  const [ title, setTitle ] = React.useState( props.match.params.id && props.match.params.id !== '0' ? `עריכת תלמיד / ${ props.match.params.id }` : 'צור תלמיד חדש' );
  const [ IsAdmin ] = React.useState( apiService.User.IsAdmin() );
  const [ IsManagerSchool ] = React.useState( IsAdmin || apiService.User.IsManagerSchool() );
  const handleChange = event => {
    try {
      event.persist();
    } catch ( e ) {
      // console.log( e );
    }

    setFormState( formState => ( {
      ...formState,
      values: {
        ...formState.values,
        [ event.target.name ]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [ event.target.name ]: true
      }
    } ) );
  };
  const GetMosads = () => {
    apiService.User.Mosads.Get().then( result => {
      console.log( 'result.data', result.data )

      if ( result.status ) {

        const ListT = result.data.map( ( option ) => {
          const firstLetter = option.mosadname[ 0 ].toUpperCase();
          return {
            firstLetter: /[0-9]/.test( firstLetter ) ? '0-9' : firstLetter,
            ...option,
          };
        } );
        console.log( "Set options", ListT );
        setSchools( ListT );
      }
    } );
  }
  const GetClasses = () => {
    // alert( formState.values.school ? formState.values.school.mosadpbx : 0 );
    if ( formState.values.school ) {
      console.log( "formState.values.school", formState.values.school )
      apiService.User.Classrooms.Get( formState.values.school.mosad_id || formState.values.school.mosadpbx ).then( result => {
        console.log( 'result.data', result.data );
        setClassList( result.data );
      } );
    }
  }
  const GetStudent = () => {
    if ( props.match.params.id ) {
      apiService.User.Students.GetSingle( props.match.params.id ).then( result => {
        const stu = result.data[ 0 ];
        if ( result.status && stu ) {
          stu.active = stu.active === 1;
          seIstNewItem( false );
          console.log( 'stu', stu );
          const copyState = { ...formState };
          copyState.values = stu;
          setFormState( copyState );
        }
      } )
    } else {
      seIstNewItem( true );
    }
  }
  const SetDefaultMosad = () => {
    let idS = props.match.params.id;
    if ( ! idS++ ) {

      const copyState = { ...formState };
      copyState.values.school = schools[ schools.length - 1 ];
      setFormState( copyState );
    }
  }
  const saveStudent = () => {
    if ( ! isNewItem ) {
      apiService.User.Students.Update( props.match.params.id, formState.values ).then( result => {
        setSnackbarOpen( true );
      } );
    } else {
      apiService.User.Students.Insert( formState.values ).then( result => {
        setSnackbarOpen( true );
        history.push( '../students' );
      } );
    }
  }
  const updateTitle = () => {
    setTitle( ! isNewItem ? `עריכת תלמיד / ${ props.match.params.id }` : 'צור תלמיד חדש' );
  }
  useEffect( GetMosads, [] )
  useEffect( GetStudent, [] )
  useEffect( GetClasses, [ formState.values.school ] )
  useEffect( SetDefaultMosad, [ schools ] )
  useEffect( updateTitle, [ isNewItem ] );

  return (
    <div className={ classes.root }>

      <Grid container spacing={ 8 } alignItems={ 'center' }>
        <Grid item alignItems={ 'center' }>
          <h1>{ title }</h1>
        </Grid>
      </Grid>
      <Grid container spacing={ 4 } alignItems={ 'center' }>
        <Grid item md={ 3 } sm={ 12 } alignItems={ 'center' }>
          <div>
            <TextField
              disabled={ ! IsAdmin  }
              value={ formState.values.id || '' }
              onChange={ handleChange }
              name="id"
              fullWidth
              helperText="מזהה"
              id="outlined-full-width"
              label="מזהה תלמיד"
              margin="normal"
              placeholder="מזהה תלמיד"
              style={ { margin: 8 } }
              variant="outlined"
              InputLabelProps={ {
                shrink: true,
              } }
            />
          </div>
        </Grid>
        <Grid item md={ 3 } sm={ 12 } alignItems={ 'center' }>
          <div>
            <TextField
              disabled={ ! IsManagerSchool }
              value={ formState.values.first || '' }
              onChange={ handleChange }
              name="first"
              fullWidth
              helperText="שם פרטי"
              id="outlined-full-width"
              label="שם פרטי"
              margin="normal"
              placeholder="שם פרטי"
              style={ { margin: 8 } }
              variant="outlined"
              InputLabelProps={ {
                shrink: true,
              } }
            />
          </div>
        </Grid>
        <Grid item md={ 3 } sm={ 12 }>
          <div>
            <TextField
              disabled={ ! IsManagerSchool }
              value={ formState.values.last || '' }
              onChange={ handleChange }
              name="last"
              helperText="שם משפחה"
              id="outlined-full-width"
              label="שם משפחה"
              margin="normal"
              placeholder="שם משפחה"
              style={ { margin: 8 } }
              variant="outlined"
              fullWidth
              InputLabelProps={ {
                shrink: true,
              } }
            />
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={ 4 }>
        <Grid item md={ 4 } sm={ 12 }>
          <Autocomplete
            //style={{ width: 300 }}
            disabled={ ! IsAdmin }
            id="teacher_selected"
            options={ schools.sort( ( a, b ) => -b.firstLetter.localeCompare( a.firstLetter ) ) }
            getOptionLabel={ ( option ) => option.mosadname ? `${ option.mosadname } ${ option.gender ? `(${ option.gender === 1 ? 'בנים' : 'בנות' })` : '' }` : '' }
            autoSelect
            openOnFocus
            multiple={ false }
            groupBy={ ( option ) => option.firstLetter }
            value={ formState.values.school }
            onChange={ ( e, value ) => {
              console.log( "value", value )
              handleChange( {
                target: {
                  name: 'school',
                  value: value
                }
              } )

            } }
            renderInput={ ( params ) => <TextField
              { ...params }
              fullWidth
              label="בחר מוסד"
              className={ classes.textField }
              variant="outlined"
            /> }

          />
        </Grid>
        <Grid item md={ 3 } sm={ 6 }>
          <TextField
            className={ classes.textField }
            fullWidth
            id="outlined-select-currency"
            label="בחר כיתה"
            disabled={ ! formState.values.school || ! IsManagerSchool }
            //onChange={ handleMosadChange }
            select
            //   style={ { margin: 8, display: 'none' } }
            //value={ mosad }
            value={ formState.values.class_id || '' }
            onChange={ handleChange }
            name="class_id"
            variant="outlined"
          >
            { classList.map( ( option ) => (
              <MenuItem
                key={ option.id }
                value={ option.id }
              >
                כיתה { option.class_room } / ( { option.sub_class_room } )
              </MenuItem>
            ) ) }
          </TextField>

        </Grid>
      </Grid>
      <Grid container spacing={ 4 }>
        <Grid item md={ 4 } sm={ 12 }>
          <div>
            <TextField
              disabled={ ! IsManagerSchool }
              value={ formState.values.phone || '' }
              onChange={ handleChange }
              name="phone"
              fullWidth
              helperText="טלפון"
              id="outlined-full-width"
              label="טלפון"
              margin="normal"
              placeholder="טלפון"
              style={ { margin: 8 } }
              variant="outlined"
              InputLabelProps={ {
                shrink: true,
              } }
            />
          </div>
        </Grid>
        <Grid item md={ 4 } sm={ 12 }>
          <div>
            <TextField
              disabled={ ! IsManagerSchool }
              value={ formState.values.phone2 || '' }
              onChange={ handleChange }
              name="phone2"
              fullWidth
              helperText="טלפון נוסף"
              id="outlined-full-width"
              label="טלפון"
              margin="normal"
              placeholder="טלפון"
              style={ { margin: 8 } }
              variant="outlined"
              InputLabelProps={ {
                shrink: true,
              } }
            />
          </div>
        </Grid>
      </Grid>

      <Grid container spacing={ 4 }>
        <Grid item md={ 4 } sm={ 12 }>
          <div>
            <TextField
              disabled={ ! IsManagerSchool }
              value={ formState.values.phone_fa || '' }
              onChange={ handleChange }
              name="phone_fa"
              fullWidth
              helperText="טלפון אב"
              id="outlined-full-width"
              label="טלפון אב"
              margin="normal"
              placeholder="טלפון אב"
              style={ { margin: 8 } }
              variant="outlined"
              InputLabelProps={ {
                shrink: true,
              } }
            />
          </div>
        </Grid>
        <Grid item md={ 4 } sm={ 12 }>
          <div>
            <TextField
              disabled={ ! IsManagerSchool }
              value={ formState.values.phone_fa2 || '' }
              onChange={ handleChange }
              name="phone_fa2"
              fullWidth
              helperText="טלפון אב נוסף"
              id="outlined-full-width"
              label="טלפון אב"
              margin="normal"
              placeholder="טלפון אב"
              style={ { margin: 8 } }
              variant="outlined"
              InputLabelProps={ {
                shrink: true,
              } }
            />
          </div>
        </Grid>
      </Grid>

      <Grid container spacing={ 4 }>
        <Grid item md={ 4 } sm={ 12 }>
          <div>
            <TextField
              disabled={ ! IsManagerSchool }
              value={ formState.values.phone_mo || '' }
              onChange={ handleChange }
              name="phone_mo"
              fullWidth
              helperText="טלפון אם"
              id="outlined-full-width"
              label="טלפון אם"
              margin="normal"
              placeholder="טלפון אם"
              style={ { margin: 8 } }
              variant="outlined"
              InputLabelProps={ {
                shrink: true,
              } }
            />
          </div>
        </Grid>
        <Grid item md={ 4 } sm={ 12 }>
          <div>
            <TextField
              disabled={ ! IsManagerSchool }
              value={ formState.values.phone_mo2 || '' }
              onChange={ handleChange }
              name="phone_mo2"
              fullWidth
              helperText="טלפון אם נוסף"
              id="outlined-full-width"
              label="טלפון אם"
              margin="normal"
              placeholder="טלפון אם"
              style={ { margin: 8 } }
              variant="outlined"
              InputLabelProps={ {
                shrink: true,
              } }
            />
          </div>
        </Grid>
      </Grid>


      <Grid container spacing={ 4 }>
        <Grid item lg={ 4 } sm={ 12 }>
          <FormControlLabel
            disabled={ ! IsManagerSchool }
            control={ <Switch name="active" checked={ formState.values.active } onChange={ handleChange }/> }
            label="פעיל"
          />


          <Grid container spacing={ 2 }>
            <Grid item xs={ 4 }>
              <Button fullWidth variant="contained" color="primary"
                      onClick={ saveStudent }>
                שמור
              </Button>
            </Grid>
            <Grid item xs={ 4 }>
              <Button fullWidth variant="contained" color="secondary"
                      onClick={ () => history.goBack() }>
                בטל
              </Button>
            </Grid>
          </Grid>


        </Grid>


        <Grid item lg={ 4 } sm={ 12 }>
          {/*<div>*/ }

          {/*  { filteredStudentsList.length && ! formState.values.allStudents ?*/ }
          {/*    <ContactList data={ filteredStudentsList } selected={ selectedList }*/ }
          {/*                 setParentList={ ( items ) => {*/ }
          {/*                   console.log( 'items selected', items );*/ }
          {/*                   setSelectedList( items );*/ }
          {/*                 } }/>*/ }
          {/*    : <div>בחר מוסד וכיתה</div> }*/ }

          {/*</div>*/ }
        </Grid>
      </Grid>


      {/*<RecordPage/>*/ }


      <Snackbar anchorOrigin={ { vertical: 'top', horizontal: 'center' } }
                open={ SnackbarOpen } autoHideDuration={ 6000 } onClose={ () => setSnackbarOpen( false ) }>
        <Alert variant="filled" severity="success">
          הפרטים עודכנו בהצלחה!
        </Alert>
      </Snackbar>
    </div>
  );
}

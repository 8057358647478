export default (location) =>{
  const queryStr = location.search && location.search.indexOf( '?' ) === 0 ? location.search.substr( 1, location.search.length ) : '';
  const params = queryStr.split( '&' );
  const obj = {};

  for ( let item of params ) {
    let k_key = item.split( '=' );
    obj[ k_key[ 0 ] ] =  k_key [ 1 ] ;
  }
  return obj;
}

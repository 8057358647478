import React, { Component } from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { Chart } from 'react-chartjs-2';
import { ThemeProvider } from '@material-ui/styles';
import validate from 'validate.js';

import { chartjs } from './helpers';
import theme from './theme';
import 'react-perfect-scrollbar/dist/css/styles.css';
import './assets/scss/index.scss';
import validators from './common/validators';
import Routes from './Routes';
import rtl from 'jss-rtl';
import { StylesProvider, jssPreset } from '@material-ui/core/styles';
import { create } from 'jss';

const browserHistory = createBrowserHistory();

Chart.helpers.extend( Chart.elements.Rectangle.prototype, {
  draw: chartjs.draw
} );

validate.validators = {
  ...validate.validators,
  ...validators
};

// Configure JSS
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
export default class App extends Component {

  render() {
    return (
      <StylesProvider jss={ jss }>
        <ThemeProvider theme={ theme }>
          <div dir='rtl'>
            <Router history={ browserHistory }>
              <Routes/>
            </Router>
          </div>
        </ThemeProvider>
      </StylesProvider>
    );
  }
}

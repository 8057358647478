import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';

import { UsersTable } from './components';
import mockData from './data';
import { Button, colors } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { DropzoneDialog } from 'material-ui-dropzone';
import apiService from '../../helpers/apiService';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import queryStringParse from '../../helpers/queryStringParse';
import ClassesTable from "./components/ClassesTable";

const useStyles = makeStyles( theme => ( {
  root: {
    padding: theme.spacing( 3 )
  },
  content: {
    marginTop: theme.spacing( 2 )
  },
  button: {
    color: colors.blueGrey[ 800 ],
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightMedium
  },
} ) );


const UserList = ( props ) => {
  const classes = useStyles();

  const [ users ] = useState( mockData );
  const [ dialogStatus, setDialogStatus ] = useState( false );
  const [ open, setOpen ] = React.useState( false );
  const [ options, setOptions ] = React.useState( [] );
  const [ refreshData, setrefreshData ] = React.useState( false );
  const [ recordDetails, setRecordDetails ] = useState(
    {
      mosad_id: apiService.User.userDetails().mosad_id,
      teacher: '',
      teacher_id: '',
      classrooms: [],
      //class: 0,
      //numclass: 0,
      //mosad_gender: '1',

    } );
  const getTeachers = () => {
    apiService.User.Teachers.Get().then( result => {
      if ( result.status ) {
        const ListT = result.data.map( ( option ) => {
          const firstLetter = option.last[ 0 ].toUpperCase();
          return {
            firstLetter: /[0-9]/.test( firstLetter ) ? '0-9' : firstLetter,
            ...option,
          };
        } );
        setOptions( ListT );
      }
    } );
  }
  //let teacherList = [];

  const handleClickOpen = () => {
    setOpen( true );
  };

  const handleClose = () => {
    setOpen( false );
    setRecordDetails( {
      ...recordDetails,
      teacher: undefined,
      teacher_id: undefined,
      teacherName: undefined
    } )
  };
  const ContinueToUpload = () => {
    setOpen( false );
    if ( recordDetails.teacher && recordDetails.mosad_id ) {
      setDialogStatus( true );
    }
  };
  const handleSelection = ( selection => {
    const classrooms = selection.map( item => item.id );
    console.log( "selection", classrooms );
    setRecordDetails( { ...recordDetails, classrooms } )
  } )

  function getRecords() {
    apiService.User.Records.Get().then( records => {
      console.log( 'records.data', records.data )
      setRecords( records.data );
      setIsLoading( false );
    } );
  }

  const [ isLoading, setIsLoading ] = useState( true );
  const [ records, setRecords ] = useState( [] );
  const onRowDelete = ( oldData ) => {
    new Promise( ( resolve, reject ) => {
      apiService.User.Records.Delete( oldData.conf ).then( result => {
        if ( result.status ) {
          let data = [ ...records ];
          const index = data.findIndex( item => item.conf === oldData.supervisor_id );
          data.splice( index, 1 );
          setRecords( data );
          resolve()
        } else {
          alert( 'Error...' );
          resolve()
        }
      } ).catch( e => {
        alert( 'ארעה שגיאה!' )
        resolve();
      } );

    } )
  }
  useEffect( getRecords, [] )

  useEffect( getTeachers, [] )
  return (
    <div className={ classes.root }>
      <Button variant="contained" color="primary" href={ `/app/confs${ props.location.search }` }>
        עבור לשיעורים חיים
      </Button>&nbsp;
      <Button variant="contained" color="primary" href={ `/app/messages${ props.location.search }` }>
        עבור להודעות
      </Button>&nbsp;
      <Button color="primary" variant="contained" onClick={ () => handleClickOpen() }>
        טען קובץ חדש
      </Button>
      <div className={ classes.content }>
        <UsersTable users={ users } data={ records } isLoading={ isLoading } onRowDelete={ onRowDelete }
                    filter={ queryStringParse( props.location ) }/>
      </div>
      <br/>
      <Button color="primary" variant="contained" onClick={ () => handleClickOpen() }>
        טען קובץ חדש
      </Button>
      <DropzoneDialog
        acceptedFiles={ [ 'audio/*', 'audio/wav', 'audio/vnd.wav', 'audio/mpeg' ] }
        cancelButtonText={ 'cancel' }
        dropzoneText={ 'טען או גרור קובץ שמע של המורה ' + recordDetails.teacherName }
        maxFileSize={ 100000000 }
        onAdd={ newFileObjs => {
          console.log( 'onAdd', newFileObjs );
          //setFileObjects([].concat(fileObjects, newFileObjs));
        } }
        onClose={ () => {
          setDialogStatus( false );
          setRecordDetails( {
            ...recordDetails,
            teacher: undefined,
            teacher_id: undefined,
            teacherName: undefined
          } )
        } }
        onDelete={ deleteFileObj => {
          console.log( 'onDelete', deleteFileObj );
        } }
        onSave={ ( files ) => {
          console.log( 'Files:', files );
          apiService.User.Records.Upload( recordDetails, files[ 0 ] ).then( res => {
            console.log( 'res', res );
            alert( 'העלאת הקובץ הושלמה' )
            setDialogStatus( false );
            getRecords();
          } );

        } }
        open={ dialogStatus }
        showFileNamesInPreview
        showPreviews
        submitButtonText={ 'Save' }
      />


      <Dialog
        aria-labelledby="form-dialog-title"
        onClose={ handleClose }
        open={ open }
        maxWidth={ 'md' }
        fullWidth
      >
        <DialogTitle id="form-dialog-title"><b>פרטי הקובץ</b></DialogTitle>
        <DialogContent>
          <DialogContentText>
            להשלמת התהליך אנא השלם את הפרטים הבאים
          </DialogContentText>
          <Autocomplete
            getOptionLabel={ ( option ) => `${ option.last } ${ option.first }` }

            groupBy={ ( option ) => option.firstLetter }
            id="teacher_selected"
            options={ options.sort( ( a, b ) => -b.firstLetter.localeCompare( a.firstLetter ) ) }

            onChange={ ( e, value ) => {
              if ( value ) {
                setRecordDetails( {
                  ...recordDetails,
                  mosad_id: value.mosad,
                  teacher: value.id,
                  teacher_id: value.teacher_id,
                  teacherName: `${ value.last } ${ value.first }`
                } )
              }
            } }
            renderInput={ ( params ) => <TextField
              { ...params }
              label="בחר מורה"
              variant="outlined"
            /> }
            style={ { width: 300 } }
          />
          <TextField
            fullWidth
            id="mosad"
            label="קוד מוסד"
            margin="dense"
            value={ recordDetails.mosad_id }
            disabled={ true }
            onChange={ ( e ) => {
              setRecordDetails( { ...recordDetails, mosad_id: e.target.value } )
            } }
            type="text"
          />

          <div>
            <TextField
              fullWidth
              id="comment"
              label="תיאור"
              margin="dense"
              type="text"
              placeholder={ 'רשום כאן תיאור כללי ועבור מי מיועד ההקלטה לשם זיהוי קל בעתיד' }
              helperText="תיאור / סינון"
              value={ recordDetails.comment || '' }
              onChange={ ( e ) => {
                setRecordDetails( { ...recordDetails, comment: e.target.value } )
              } }
            />


          </div>
          <br/>
          <ClassesTable filter={ queryStringParse( props.location ) } selectedClass={ [] }
                        onChange={ handleSelection }></ClassesTable>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={ handleClose }
          >
            ביטול
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={ ContinueToUpload }
            disabled={ ! ( recordDetails.teacher && recordDetails.mosad_id && recordDetails.classrooms.length ) }
          >
            המשך לטעינת קובץ
          </Button>
        </DialogActions>
      </Dialog>

    </div>
  );
};

export default UserList;



import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';

import { UsersTable } from '../components';
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import apiService from "../../helpers/apiService";

const useStyles = makeStyles( theme => ( {
  root: {
    padding: theme.spacing( 3 )
  },
  content: {
    marginTop: theme.spacing( 2 )
  }
} ) );


const QuizList = ( props ) => {
  const classes = useStyles();
  const { history } = props;
  const tableProps = {
    columns: [
      { title: 'מזהה', field: 'id', defaultSort: 'desc' },
      { title: 'שם קמפיין', field: 'name' },
      { title: 'תיאור', field: 'description' },
      { title: 'מוסד', field: 'schools_length', render: r => `${ r.schools_length } מוסדות נבחרו` },
      {
        title: 'מצב',
        field: 'status',
        lookup: { 0: 'לא הופעל', 1: 'מופעל', 2: 'מכין לשליחה', 3: 'מריץ כעת', 4: 'נכשל', 10: 'הסתיים' },
      },
      { title: 'תאריך הפעלה', field: 'run_date', type: 'date' }
    ],
    actions:
      [
        {
          icon: 'visibility',
          tooltip: 'הצג מצב',
          onClick: ( event, rowData ) => alert( 'עדיין לא זמין! בקרוב...' ),
          disabled: false
        },
      ],
    options: {
      filtering: true,
      exportButton: true,
      sorting: true,
      exportAllData: true,
      pageSize: 20,
      rowStyle: rowData => ( {
        color: ( rowData.status === 10 ) ? 'rgb(116, 116, 116)' : ( rowData.status === 2 ? 'green' : '#000' )
      } )
    },
    onRowClick: ( e, rowData ) => {
      // if ( rowData.status === 0 )
      //   history.push( `/app/create-message/${ rowData.id }` )
      // else
        history.push( `/app/quiz/${ rowData.id }` )
    }
  }
  const [ campaigns, setCampaigns ] = useState( [] );

  function getQuiz() {
    apiService.User.Quiz.Get().then( result => {
      // console.log(result.data)
      setCampaigns( result.data );
    } );
  }

  useEffect( getQuiz, [] )
  return (
    <div className={ classes.root }>
      <Button variant="contained" color="primary" onClick={ () => history.push( './create-message' ) }>
        צור חידון חדש
      </Button>
      <div className={ classes.content }>
        <UsersTable title={ 'רשימת חידונים' } history={ props.history } columns={ tableProps.columns }
                    actions={ tableProps.actions }
                    options={ tableProps.options } onRowClick={ tableProps.onRowClick } data={ campaigns }/>
      </div>
      <br/>
      <Button variant="contained" color="primary" onClick={ () => history.push( './quiz/0' ) }>
        צור חידון חדש
      </Button>


    </div>
  );
};

export default QuizList;

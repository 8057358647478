import React, { useState, useEffect } from 'react';
import MaterialTable from 'material-table'
import apiService from '../../../../helpers/apiService';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

const path_url = `${ apiService.API_DOMAIN }/records/play/`;
String.prototype.toHHMMSS = function () {
  var sec_num = parseInt( this, 10 ); // don't forget the second param
  var hours = Math.floor( sec_num / 3600 );
  var minutes = Math.floor( ( sec_num - ( hours * 3600 ) ) / 60 );
  var seconds = sec_num - ( hours * 3600 ) - ( minutes * 60 );

  if ( hours < 10 ) {
    hours = '0' + hours;
  }
  if ( minutes < 10 ) {
    minutes = '0' + minutes;
  }
  if ( seconds < 10 ) {
    seconds = '0' + seconds;
  }
  return hours + ':' + minutes + ':' + seconds;
}

const ClassesTable = props => {
  const [ values ] = useState( {

    columns: [
      //{ title: 'תאריך', field: 'stime', type: 'datetime', defaultSort: 'desc' },
      { title: 'שם מוסד', field: 'mosadname', editable: 'never' },
      { title: 'מגדר', field: 'gender', lookup: { 1: 'בנים', 2: 'בנות' }, editable: 'never' },
      { title: 'סמל מוסד', field: 'mosadpbx',editable:'onAdd' },
      { title: 'כיתה', field: 'class_he', },
      { title: 'כיתה/מספר', field: 'class_room', },
      { title: 'מקבילה', field: 'sub_class_room' },

    ]
  } );
  const [ isLoading, setIsLoading ] = useState( true );
  const [ classList, setClassList ] = useState( [] );
  const [ dialogStatus, setDialogStatus ] = useState( false );
  const [ singleRecord, setSingleRecord ] = useState( {} );

  function closeDialog() {
    setDialogStatus( false );
  }

  function getClassrooms() {
    apiService.User.Classrooms.Get().then( records => {
      setClassList( records.data );
      setIsLoading( false );
    } );
  }

  useEffect( getClassrooms, [] )
  return (
    <div>
      <MaterialTable
        columns={ values.columns }
        data={ classList }
        isLoading={ isLoading }
        //localization={ { header: { actions: 'בחר' } } }
        options={ {
          filtering: true,
          exportAllData: true,
          pageSize: 20,
          addRowPosition: 'first',
          //selection: true,
        } }
        editable={ {
          onRowUpdate: ( newData, oldData ) =>
            new Promise( ( resolve, reject ) => {
              setIsLoading( true );
              apiService.User.Classrooms.Update( oldData.id, newData ).then( result => {
                if ( result.status ) {
                  let data = [ ...classList ];
                  const index = data.findIndex( item => item.id === oldData.id );
                  data[ index ] = newData;
                  setClassList( data );
                  resolve()
                } else {
                  alert( 'ארעה שגיאה בעדכון נתונים. נא בדוק שכל הפרטים הוזנו כראוי' );
                  resolve();
                }
                setIsLoading( false );
              } );

            } ),
          onRowAdd: apiService.User.IsAdmin() ? newData =>
            new Promise( ( resolve, reject ) => {
              setIsLoading( true );
              apiService.User.Classrooms.Insert( newData ).then( result => {
                if ( result.status ) {
                  let data = [ ...classList ];
                  newData.id = result.data.id;
                  data.unshift( newData );
                  setClassList( data );
                  resolve();
                } else {
                  alert( 'ארעה שגיאה בהכנסת נתונים. נא בדוק שכל הפרטים הוזנו כראוי' );
                  resolve();
                }
                setIsLoading( false );
              } );
            } ) : null,
        } }
        title={ <h4>בחר כיתות</h4> }
      />
      <Dialog
        aria-labelledby="draggable-dialog-title"
        onClose={ closeDialog }
        open={ dialogStatus }
      >
        <DialogTitle id="draggable-dialog-title">{ singleRecord.id } האזנה </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Listing record: { singleRecord.id }. from { singleRecord.first } { singleRecord.last }.
            (school: { singleRecord.mosadname })
            <h5>classroom></h5>
            <audio
              autoPlay
              controls
              src={ `${ path_url }${ singleRecord.conf }/${ singleRecord.mosadpbx }` }
            >
              Your browser does not support the
              <code>audio</code> element.
            </audio>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  )
};


export default ClassesTable;

import React, { useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Avatar, Typography } from '@material-ui/core';
import apiService from '../../../../../../helpers/apiService';
import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles( theme => ( {
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'fit-content'
  },
  avatar: {
    width: 60,
    height: 60
  },
  name: {
    marginTop: theme.spacing( 1 )
  }
} ) );

const Profile = props => {
  const { className, ...rest } = props;

  const classes = useStyles();
  const user = {
    name: apiService.User.userDetails().name,// props.user.name,// 'אביגדור',
    avatar: '',
    bio: apiService.User.IsAdmin() ? 'מנהל כללי' : apiService.User.IsManagerSchool() ? 'חשבון מוסד' : apiService.User.IsTeacher() ? 'חשבון מורה' : 'חשבון מפקח',
    mosad_id: apiService.User.userDetails().mosads ? apiService.User.userDetails().mosads.split( ',' )[ 0 ] : undefined,
  };
  const [ mosadDetails, setMosadDetails ] = React.useState( {} );
  const [ allowMosadPage, setAllowMosadPage ] = React.useState( false );

  const GetMosad = () => {
    if ( apiService.User.IsAdmin() ) {
      setMosadDetails( { mosadname: 'כל המוסדות', city: 'כל הארץ' } );
      setAllowMosadPage( true )
      return;
    }
    if ( apiService.User.IsManagerSchool() ) {
      setAllowMosadPage( true )
    }
    if ( ! user.mosad_id ) {
      return;
    }
    apiService.User.Mosads.Get( user.mosad_id ).then( result => {
      console.log( 'Mosads .data', result.data )
      if ( result.status ) {

        const did = result.data[ 0 ].did;

        if ( did  ) {
          result.data[ 0 ].did_number = `0${ did }`;
        }
        setMosadDetails( result.data[ 0 ] );
      }
    } );
  }

  useEffect( GetMosad, [] )

  return (
    <div
      { ...rest }
      className={ clsx( classes.root, className ) }
    >
      <Avatar
        alt="Person"
        className={ classes.avatar }
        component={ RouterLink }
        src={ user.avatar }
        to={ `${ allowMosadPage ? "/app/mosads" : "/" }` }
      />
      <Typography
        className={ classes.name }
        variant="h4"
      >
        { user.name }
      </Typography>
      <Typography variant="body2">{ user.bio }</Typography>
      <Typography variant="body2">{ mosadDetails.mosadname }/{ mosadDetails.city }</Typography>
      <Typography variant="body2"> { mosadDetails.mosad }</Typography>
      <Divider/>
      <Typography variant="h4">{ mosadDetails.did_number }</Typography>
    </div>
  )
    ;
};

Profile.propTypes = {
  className: PropTypes.string
};

export default Profile;

import React, { useState, useEffect } from 'react';
import MaterialTable from 'material-table'
import apiService from "../../../../helpers/apiService";


const UsersTable = props => {
  const [ values, setValues ] = useState( {

    columns: [
      { title: 'שם פרטי', field: 'first' },
      { title: 'שם משפחה', field: 'last' },
      { title: 'קוד', field: 'id' },
      { title: 'מצב', field: 'active', lookup: { 1: 'פעיל', 0: 'חסום' } },
    ]
  } );
  const [ supervisors, setSupervisors ] = useState( [] );

  function getTeachers() {
    apiService.User.Supervisors.Get().then( supervisors => {
      setSupervisors( supervisors.data );
    } );
  }


  useEffect( getTeachers, [] )
  return (
    <MaterialTable
      title={<h2>רשימת מפקחים</h2>}
      columns={ values.columns }
      data={ supervisors }
      localization={ { header: { actions: 'פעולות' } } }
      options={ {
        filtering: true,
        exportButton: true,
        sorting: true,
        exportAllData: true,
        pageSize: 20,
        addRowPosition: 'first'
      } }
      editable={ {
        onRowAdd: newData =>
          new Promise( ( resolve, reject ) => {
            apiService.User.Supervisors.Insert( newData ).then( result => {
              if ( result.status ) {
                newData.supervisor_id = result.data.id;
                let data = [ ...supervisors ];

                data.unshift( newData );
                setSupervisors( data );
                resolve();
              } else {
                alert( 'ארעה שגיאה בהכנסת נתונים. נא בדוק שכל הפרטים הוזנו כראוי' );
                resolve();
              }
            } );
          } ),
        onRowUpdate: ( newData, oldData ) =>
          new Promise( ( resolve, reject ) => {
            apiService.User.Supervisors.Update( oldData.supervisor_id, newData ).then( result => {
              if ( result.status ) {
                let data = [ ...supervisors ];
                const index = data.findIndex( item => item.supervisor_id === oldData.supervisor_id );
                data[ index ] = newData;
                setSupervisors( data );
                resolve()
              } else {
                alert( 'ארעה שגיאה בעדכון נתונים. נא בדוק שכל הפרטים הוזנו כראוי' );
                resolve();
              }
            } );

          } ),
        // onRowDelete: ( oldData ) =>
        //   new Promise( ( resolve, reject ) => {
        //     apiService.User.Supervisors.Delete( oldData.supervisor_id ).then( result => {
        //       if ( result.status ) {
        //         let data = [ ...supervisors ];
        //         const index = data.findIndex( item => item.supervisor_id === oldData.supervisor_id );
        //         data.splice( index, 1 );
        //         setSupervisors( data );
        //         resolve()
        //       } else {
        //         alert( 'Error...' );
        //         resolve()
        //       }
        //     } ).catch( e => {
        //       alert( 'ארעה שגיאה!' )
        //       resolve();
        //     } );
        //
        //   } )
      } }
    />
  )
};


export default UsersTable;

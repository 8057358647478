import React, { useState } from 'react';
import clsx from 'clsx';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardActions,
  CardHeader,
  CardContent,
  Button,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  TableSortLabel
} from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

import mockData from './data';
import { StatusBullet } from 'components';

const useStyles = makeStyles( theme => ( {
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 800
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  status: {
    marginRight: theme.spacing( 1 )
  },
  actions: {
    justifyContent: 'flex-end'
  }
} ) );

const statusColors = {
  delivered: 'success',
  pending: 'info',
  refunded: 'danger'
};

const LatestOrders = props => {
  const { className, data, ...rest } = props;

  const classes = useStyles();

  const [ orders ] = useState( mockData );
  console.log( "data", data )
  return (
    <Card
      { ...rest }
      className={ clsx( classes.root, className ) }
    >
      <CardHeader

        title="חדרי ועידה פעילים כעת"
      />
      <Divider/>
      <CardContent className={ classes.content }>
        <PerfectScrollbar>
          <div className={ classes.inner }>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>מזהה מוסד</TableCell>
                  <TableCell>שם מוסד</TableCell>
                  <TableCell sortDirection="desc">
                    <Tooltip
                      enterDelay={ 300 }
                      title="Sort"
                    >
                      <TableSortLabel
                        active
                        direction="desc"
                      >
                        חדרי ועידה
                      </TableSortLabel>
                    </Tooltip>
                  </TableCell>
                  <TableCell>סך משתתפים</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                { data.map( order => (
                  <TableRow
                    hover
                    key={ order.id }
                  >
                    <TableCell>{ order.mosadpbx }</TableCell>
                    <TableCell>{ order.mosadname }</TableCell>
                    <TableCell>
                      {/*{ moment( order.createdAt ).format( 'DD/MM/YYYY' ) }*/}
                      {order.count}
                    </TableCell>
                    <TableCell>
                      <div className={ classes.statusContainer }>
                        <StatusBullet
                          className={ classes.status }
                          color={ statusColors[ order.status ] }
                          size="sm"
                        />
                        {/*{ order.status }*/}
                        {order.sumIn}
                      </div>
                    </TableCell>
                  </TableRow>
                ) ) }
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <Divider/>
      {/*<CardActions className={ classes.actions }>*/}
      {/*  <Button*/}
      {/*    color="primary"*/}
      {/*    size="small"*/}
      {/*    variant="text"*/}
      {/*  >*/}
      {/*    View all <ArrowRightIcon/>*/}
      {/*  </Button>*/}
      {/*</CardActions>*/}
    </Card>
  );
};

LatestOrders.propTypes = {
  className: PropTypes.string
};

export default LatestOrders;

import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Card, CardContent, Grid, Typography, Avatar } from '@material-ui/core';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import PeopleIcon from '@material-ui/icons/PeopleOutlined';
import MoneyIcon from '@material-ui/icons/MoneyOutlined';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';


const useStyles = makeStyles( theme => ( {
  root: {
    height: '100%'
  },
  content: {
    alignItems: 'center',
    display: 'flex'
  },
  title: {
    fontWeight: 700
  },
  avatar: {
    backgroundColor: theme.palette.success.main,
    height: 56,
    width: 56
  },
  icon: {
    height: 32,
    width: 32
  },
  difference: {
    marginTop: theme.spacing( 2 ),
    display: 'flex',
    alignItems: 'center'
  },
  differenceIcon: {
    color: theme.palette.success.dark
  },
  differenceValue: {
    color: theme.palette.success.dark,
    marginRight: theme.spacing( 1 )
  }
} ) );

const TotalUsers = props => {
  const { className, widget, ...rest } = props;
  console.log( 'widget', widget )
  const classes = useStyles();
  const icon = () => {
    switch ( widget.icon ) {

      case 'MoneyIcon':
        return <MoneyIcon className={ classes.icon }/>;
      case 'AttachMoneyIcon':
        return <AttachMoneyIcon className={ classes.icon }/>;
      default:
        return <PeopleIcon className={ classes.icon }/>;

    }
  }
  return (
    <Card
      { ...rest }
      className={ clsx( classes.root, className ) }
    >
      <CardContent>
        <Grid
          container
          justify="space-between"
        >
          <Grid item>
            <Typography
              className={ classes.title }
              color="textSecondary"
              gutterBottom
              variant="body2"
            >
              { widget.title }
            </Typography>
            <Typography variant="h3">{ widget.value }</Typography>
          </Grid>
          <Grid item>
            <Avatar className={ classes.avatar }>
              { icon() }
              {/*<PeopleIcon className={ classes.icon }/>*/ }
            </Avatar>
          </Grid>
        </Grid>

        { ( () => {
          if ( widget.extraValue!=='undefined' ) {
            return <div className={ classes.difference }>
              <ArrowUpwardIcon className={ classes.differenceIcon }/>
              <Typography
                className={ classes.differenceValue }
                variant="body2"
              >
                { widget.extraValue }
              </Typography>
              <Typography
                className={ classes.caption }
                variant="caption"
              >
                { widget.extraTitle }
              </Typography>
            </div>
          }
        } )() }


      </CardContent>
    </Card>
  );
};

TotalUsers.propTypes = {
  className: PropTypes.string
};

export default TotalUsers;

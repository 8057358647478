import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { AppBar, Toolbar, Badge, Hidden, IconButton } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import InputIcon from '@material-ui/icons/Input';
import user from "../../../../helpers/apiService";

const useStyles = makeStyles( theme => ( {
  root: {
    boxShadow: 'none'
  },
  flexGrow: {
    flexGrow: 1
  },
  signOutButton: {
    marginLeft: theme.spacing( 1 )
  }
} ) );

const Topbar = props => {
  const { className, onSidebarOpen, ...rest } = props;

  const classes = useStyles();

  const [ notifications ] = useState( [] );

  function LogOut() {
    console.log( 'logout' );
    console.log( user.User );

    user.User.LogOut();
    console.log( 'isLoggedIn', user.User.isLoggedIn() )





  }

  return (
    <AppBar
      { ...rest }
      className={ clsx( classes.root, className ) }
    >
      <Toolbar>
        <RouterLink to="/app/confs">
          <img
            alt="Logo"
            src="/images/logos/shas.png"
          />
        </RouterLink>
        <div className={ classes.flexGrow }/>
        {/*<Hidden mdDown>*/ }
        {/*  <IconButton color="inherit">*/ }
        {/*    <Badge*/ }
        {/*      badgeContent={notifications.length}*/ }
        {/*      color="primary"*/ }
        {/*      variant="dot"*/ }
        {/*    >*/ }
        {/*      <NotificationsIcon />*/ }
        {/*    </Badge>*/ }
        {/*  </IconButton>*/ }
        <IconButton
          className={ classes.signOutButton }
          color="inherit"
          onClick={ LogOut }
        >
          <InputIcon/>
        </IconButton>
        {/*</Hidden>*/ }
        <Hidden lgUp>
          <IconButton
            color="inherit"
            onClick={ onSidebarOpen }
          >
            <MenuIcon/>
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func
};

export default Topbar;

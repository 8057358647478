import React, { useState, useEffect } from 'react';
import MaterialTable from 'material-table'
import apiService from '../../../../helpers/apiService';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

const path_url = `${ apiService.API_DOMAIN }/records/play/`;
String.prototype.toHHMMSS = function () {
  var sec_num = parseInt( this, 10 ); // don't forget the second param
  var hours = Math.floor( sec_num / 3600 );
  var minutes = Math.floor( ( sec_num - ( hours * 3600 ) ) / 60 );
  var seconds = sec_num - ( hours * 3600 ) - ( minutes * 60 );

  if ( hours < 10 ) {
    hours = '0' + hours;
  }
  if ( minutes < 10 ) {
    minutes = '0' + minutes;
  }
  if ( seconds < 10 ) {
    seconds = '0' + seconds;
  }
  return hours + ':' + minutes + ':' + seconds;
}

const UsersTable = props => {
  const [ values ] = useState( {

    columns: [
      { title: 'תאריך', field: 'stime', type: 'date', defaultSort: 'desc', editable: 'never' },
      { title: 'שעה', field: 'stime', type: 'time', editable: 'never' },
      { title: 'קוד מוסד', field: 'mosadpbx', editable: 'never' },
      { title: 'שם מוסד', field: 'mosad', editable: 'never', render: rowData => rowData.mosads || rowData.mosad },
      { title: 'תיאור', field: 'comment' },
      //{ title: 'סמל מוסד', field: 'mosad', defaultFilter: props.filter.mosad },
      // { title: 'מורה משפחה', field: 'last' },
      // { title: 'מורה פרטי', field: 'first' },
      {
        title: 'מורה',
        field: 'teacher_id',
        defaultFilter: props.filter.teacher_id,
        render: rowData => `${ rowData.last } ${ rowData.first }`,
        customFilterAndSearch: ( ( filterStr, rowData ) => {
          return rowData.teacher_id === parseInt( filterStr ) || ( rowData.last && rowData.last.indexOf( filterStr ) >= 0 ) || ( rowData.first && rowData.first.indexOf( filterStr ) >= 0 )
        } )
      },
      // { title: 'כיתה', field: 'class', },
      // { title: 'מקבילה', field: 'numclass' },
      { title: 'מספר הקלטה', field: 'conf', editable: 'never' },
      { title: 'ארכיון', field: 'archive', editable: 'never' },
      { title: 'כיתה', field: 'class', editable: 'never' },
      { title: 'מקבילה', field: 'numclass', editable: 'never' },
      {
        title: 'משך',
        field: 'duration',
        editable: 'onAdd',
        render: rowData => `${ rowData.duration ? rowData.duration.toString().toHHMMSS() : '--' }`
      },
      { title: 'מקור', field: 'src_file', lookup: { 1: 'הקלטת קובץ', 2: 'העלאת קובץ', 3: 'הקלטת שיעור חי' } },

    ]
  } );
  const { data, isLoading,onRowDelete } = props;
  const [ dialogStatus, setDialogStatus ] = useState( false );
  const [ singleRecord, setSingleRecord ] = useState( {} );

  function closeDialog() {
    setDialogStatus( false );
  }

  return (
    <div>
      <MaterialTable
        actions={ [
          {
            icon: '▶',
            tooltip: 'Play file',
            onClick: ( event, rowData ) => {
              setSingleRecord( rowData );
              setDialogStatus( true );
            }
          }
        ] }
        columns={ values.columns }
        data={ data }
        localization={ { header: { actions: 'האזנה' } } }
        isLoading={ isLoading }
        options={ {
          filtering: true,
          exportButton: true,
          isLoading: { isLoading },
          pageSize: 20
        } }
        title={ <h2>רשימת הקלטות</h2> }
        editable={ {
          onRowUpdate: () => {
          },
          onRowDelete: onRowDelete
        } }
      />
      <Dialog
        aria-labelledby="draggable-dialog-title"
        onClose={ closeDialog }
        open={ dialogStatus }
      >
        <DialogTitle id="draggable-dialog-title">{ singleRecord.id } האזנה </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Listing record: { singleRecord.id }. from { singleRecord.first } { singleRecord.last }.
            (school: { singleRecord.mosadname })
            {/*<h5>Click to play</h5>*/ }
            <audio
              autoPlay
              controls
              src={ `${ path_url }${ singleRecord.conf }/${ singleRecord.mosadpbx }` }
            >
              Your browser does not support the
              <code>audio</code> element.
            </audio>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  )
};


export default UsersTable;

import axios from 'axios';

const userSer = {
  isLoggedIn: false
}
//const API_DOMAIN = 'http://localhost:3600';
const API_DOMAIN = 'https://calls.m-m.org.il';
const ApiService = {
  x: 123,
  isLoggedIn: () => {
    return userSer.LoggedIn;
  },
  userDetails: () => {
    if ( ! userSer.details ) {
      userSer.details = window.localStorage[ 'user' ] ? JSON.parse( window.localStorage[ 'user' ] ) : {};
    }
    return {
      email: userSer.email,
      name: userSer.details.name,
      mosad_id: userSer.details.mosad_id,
      mosads: userSer.details.mosads
    }
  },
  IsAdmin: () => {
    if ( ! userSer.details ) {
      userSer.details = window.localStorage[ 'user' ] ? JSON.parse( window.localStorage[ 'user' ] ) : {};
    }
    return userSer.details.user_type === 10;
  },
  IsManagerSchool: () => {
    if ( ! userSer.details ) {
      userSer.details = window.localStorage[ 'user' ] ? JSON.parse( window.localStorage[ 'user' ] ) : {};
    }
    return userSer.details.user_type === 5;
  },
  IsTeacher: () => {
    if ( ! userSer.details ) {
      userSer.details = window.localStorage[ 'user' ] ? JSON.parse( window.localStorage[ 'user' ] ) : {};
    }
    return userSer.details.user_type === 2;
  },
  SignIn: async ( data ) => {
    console.log( data )
    const resultLogin = await API_POST( '/auth/signin', data );
    console.log( 'resultLogin', resultLogin );
    if ( resultLogin.status ) {
      window.localStorage[ 'token' ] = resultLogin.data.token;
      window.localStorage[ 'user' ] = JSON.stringify( resultLogin.data.user );
      userSer.details = resultLogin.data.user;
      userSer.LoggedIn = true;
      userSer.email = data.email;
      return Promise.resolve( true );
    }
    return Promise.resolve( false );
  },
  LogOut: () => {
    window.localStorage.clear();
    delete ApiService.email;
    ApiService.LoggedIn = false;
    window.location = '/app/sign-in';
  },
  Mosads: {
    Get: async ( id = undefined ) => {
      return API_GET( `/mosads${ id ? `/${ id }` : '' }` );
    },
    Update: async ( id, mosadItem ) => {
      return API_PUT( `/mosads/${ id }`, mosadItem );
    },
    Insert: async ( mosadItem ) => {
      return API_POST( '/mosads', mosadItem );
    },
    Delete: async ( mosad_id ) => {
      return API_DELETE( `/mosads/${ mosad_id }` );
    },
    Upload: async ( recordDetails, files ) => {
      const data = new FormData()
      data.append( 'file', files );
      data.append( 'body', JSON.stringify( recordDetails ) );
      return new Promise( ( resolve, reject ) => {
        axios.post( `${ API_DOMAIN }/records/upload/intro`, data, {
          // receive two    parameter endpoint url ,form data
          headers: {
            'Token': window.localStorage[ 'token' ]
          }
        } ).then( res => {
          console.log( 'res', res );
          resolve( res )
        } ).catch( reject );
      } )
    },
    Play: async ( mosadid ) => {
      return API_GET( `/records/intro/play/${ mosadid }` );
    },
    RemoveIntro: async ( mosadpbx ) => {
      return API_POST( `/records/remove/intro`, { mosadpbx } )
    }

  },
  Classrooms: {
    Get: async ( id = undefined ) => {
      return API_GET( `/classrooms${ id ? `/${ id }` : '' }` );
    },
    Insert: async ( classItem ) => {
      return API_POST( '/classrooms', classItem );
    },
    Update: async ( id, classItem ) => {
      return API_PUT( `/classrooms/${ id }`, classItem );
    },
  },
  Users: {
    Get: async ( id = undefined ) => {
      return API_GET( `/users${ id ? `/${ id }` : '' }` );
    },
    Update: async ( id, mosadItem ) => {
      return API_PUT( `/users/${ id }`, mosadItem );
    },
    Insert: async ( mosadItem ) => {
      return API_POST( '/users', mosadItem );
    },
    Delete: async ( user_id ) => {
      return API_DELETE( `/users/${ user_id }` );
    }
  },
  Teachers: {
    Get: async ( school_id = undefined ) => {
      return API_GET( `/teachers${ school_id ? `/school/${ school_id }` : '' }` );
    },
    Update: async ( id, mosadItem ) => {
      return API_PUT( `/teachers/${ id }`, mosadItem );
    },
    Insert: async ( mosadItem ) => {
      return API_POST( '/teachers', mosadItem );
    },
    Delete: async ( mosad_id ) => {
      return API_DELETE( `/teachers/${ mosad_id }` );
    }
  },
  Supervisors: {
    Get: async ( id = undefined ) => {
      return API_GET( `/supervisors${ id ? `/${ id }` : '' }` );
    },
    Update: async ( id, item ) => {
      return API_PUT( `/supervisors/${ id }`, item );
    },
    Insert: async ( item ) => {
      return API_POST( '/supervisors', item );
    },
    Delete: async ( id ) => {
      return API_DELETE( `/supervisors/${ id }` );
    }
  },
  Students: {
    Get: async ( school_id = undefined ) => {
      return API_GET( `/students${ school_id ? `/school/${ school_id }` : '' }` );
    },
    GetSingle: async ( student_id ) => {
      return API_GET( `/students/${ student_id }` );
    },
    Update: async ( id, mosadItem ) => {
      return API_PUT( `/students/${ id }`, mosadItem );
    },
    Insert: async ( mosadItem ) => {
      return API_POST( '/students', mosadItem );
    },
    Delete: async ( id ) => {
      return API_DELETE( `/students/${ id }` );
    }
  },
  Messages: {
    Get: async () => {
      return API_GET( '/records?messages=1' );
    }
  },
  Records: {
    Get: async ( id = undefined ) => {
      return API_GET( `/records${ id ? `/${ id }` : '' }` );
    },
    Upload: async ( recordDetails, files, isMessageRecord = false ) => {
      const data = new FormData()
      data.append( 'file', files );
      data.append( 'body', JSON.stringify( recordDetails ) );
      return new Promise( ( resolve, reject ) => {
        axios.post( `${ API_DOMAIN }/records/upload?isMessageRecord=${ isMessageRecord }`, data, {
          // receive two    parameter endpoint url ,form data
          headers: {
            'Token': window.localStorage[ 'token' ]
          }
        } ).then( res => {
          console.log( 'res', res );
          resolve( res )
        } ).catch( reject );
      } )
    },
    Play: async ( id, mosadItem ) => {
      return API_GET( `/records/play/${ id }` );
    },
    Delete: async ( id ) => {
      return API_DELETE( `/records/${ id }` );
    }
  },
  Files: {
    Get: async ( id = undefined ) => {
      return API_GET( `/files${ id ? `/${ id }` : '' }` );
    },
    Upload: async ( recordDetails, files ) => {
      const data = new FormData()
      data.append( 'file', files );
      data.append( 'body', JSON.stringify( recordDetails ) );
      return new Promise( ( resolve, reject ) => {
        axios.post( `${ API_DOMAIN }/files/upload`, data, {
          // receive two    parameter endpoint url ,form data
          headers: {
            'Token': window.localStorage[ 'token' ]
          }
        } ).then( res => {
          console.log( 'res', res );
          resolve( res.data );
        } );
      } );
      return;
    },
    Play: async ( id, mosadItem ) => {
      return API_GET( `/files/play/${ id }` );
    }
  },
  Confs: {
    Get: async ( id = undefined ) => {
      return API_GET( `/confs${ id ? `/${ id }` : '' }` );
    }
  },
  VoiceCampaigns: {
    Get: async ( id = undefined ) => {
      return API_GET( `/campaigns${ id ? `/${ id }` : '' }` );
    },
    Update: async ( id, data ) => {
      return API_PUT( `/campaigns/${ id }`, data );
    },
    Insert: async ( data ) => {
      return API_POST( '/campaigns', data );
    },
    Run: async ( id ) => {
      return API_POST( `/campaigns/${ id }/status`, { status: 1 } );
    },
    Stop: async ( id ) => {
      return API_POST( `/campaigns/${ id }/status`, { status: 0 } );
    },
    GetContacts: async ( id = undefined ) => {
      return API_GET( `/campaigns/${ id }/contacts` );
    },
  },
  ActivityLogs: {
    Get: async ( id = undefined ) => {
      return API_GET( `/logs/students${ id ? `/${ id }` : '' }` );
    }
  },
  ActivityLogsTeachers: {
    Get: async ( id = undefined ) => {
      return API_GET( `/logs/teachers${ id ? `/${ id }` : '' }` );
    }
  },
  Dashboard: {
    UsageData: async () => {
      return API_GET( '/dashboard' );
    }
  },
  Quiz: {
    Get: async ( id = undefined ) => {
      return API_GET( `/quiz${ id ? `/${ id }` : '' }` );
    },
    Update: async ( id, data ) => {
      return API_PUT( `/quiz/${ id }`, data );
    },
    Insert: async ( data ) => {
      return API_POST( '/quiz', data );
    },
    GetQuestions: async ( id = undefined ) => {
      return API_GET( `/quiz/${ id }/questions` );
    },
    UpdateQuestion: async ( id, questionId, data ) => {
      return API_PUT( `/quiz/${ id }/questions/${ questionId }`, data );
    },
    InsertQuestion: async ( id, data ) => {
      return API_POST( `/quiz/${ id }/questions`, data );
    },
  }
}
// function ApiService() {
//   let x = 123;
//   return {
//     x
//   }
// }
const API_GET = async ( path ) => {
  return EXE_API( 'GET', path );
}
const API_POST = async ( path, data ) => {
  return EXE_API( 'POST', path, data );
}
const API_PUT = async ( path, data ) => {
  return EXE_API( 'PUT', path, data );
}
const API_DELETE = async ( path, data ) => {
  return EXE_API( 'DELETE', path, data );
}
const EXE_API = async ( method, path, data ) => {
  return new Promise( ( resolve, reject ) => {
    const init = {
      method: method || 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Token': window.localStorage[ 'token' ]
      }
    }
    if ( method === 'POST' || method === 'PUT' ) {
      init.body = JSON.stringify( data || {} )
    }
    fetch( `${ API_DOMAIN }${ path }`, init )
      .then( ( response ) => {
        //console.log( "response", response );
        if ( response.status === 401 )
          return ApiService.LogOut();
        return response.json();
      } )
      .then( ( jsonObject ) => {
        resolve( jsonObject );
      } )
  } );
}
export default {
  User: ApiService,
  API_DOMAIN
}
// export default () => {
//
//   console.log( 'run' );
//   return {
//     num: 123,
//     signIn: ( data ) => {
//       return true;
//     }
//   };
// }
//export default () => !!store.get('loggedIn');

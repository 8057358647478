import React, { useState, useEffect } from 'react';
import MaterialTable from 'material-table'
import apiService from '../../../../helpers/apiService';
import makeStyles from "@material-ui/core/styles/makeStyles";

const UsersTable = props => {
  const history = props.history;
  const [ values ] = useState( {

    columns: [
      { title: 'שם משפחה', field: 'last', defaultSort: 'asc' },
      { title: 'שם פרטי', field: 'first' },
      { title: 'סמל מוסד', field: 'mosad' },
      // {
      //   title: 'מוסד', field: 'mosadpbx',
      //   editable: apiService.User.IsAdmin() ? 'always' : 'never',
      //   render: rowData => `${ rowData.mosadname ? `${ rowData.mosadname } - ${ rowData.city }` : rowData.mosadpbx } (${ ( rowData.gender === 1 ? 'בנים' : 'בנות' ) })`
      // },
      { title: 'מוסד', field: 'mosadname', editable: 'never' },
      { title: 'עיר', field: 'city', editable: 'never' },
      // { title: 'מוסד לטלפוניה', field: 'mosadpbx' },
      { title: 'מגדר', field: 'gender', lookup: { 1: 'בנים', 2: 'בנות' } },
      {
        title: 'כיתה', field: 'classroom',
        // render: rowData => `${ rowData.classroom }`
      },
      // { title: 'שכבה', field: 'class' },
      // { title: 'מקבילה', field: 'numclass' },
      { title: 'טלפון', field: 'phone' },
      // { title: 'טלפון', field: 'phone2' },
      { title: 'טלפון אב', field: 'phone_fa' },
      // { title: 'טלפון אב', field: 'phone_fa2' },
      { title: 'טלפון אם', field: 'phone_mo' },
      // { title: 'טלפון אם', field: 'phone_mo2' },
      {
        title: 'קוד לטלפון', field: 'id', editable: 'onAdd',
        render: rowData => parseInt( rowData.id ) < 100000 ? 'ממתין לקבלת קוד' : rowData.id
      },
      { title: 'מצב', field: 'active', lookup: { 1: 'פעיל', 0: 'חסום' } },
      { title: 'פעיל', field: 'isLive', lookup: { 1: 'בשיעור חי', 0: '', 2: 'בהקלטה' } },
    ],
    //: true

  } );
  const [ isLoading, setIsLoading ] = useState( true );
  const [ students, setStudents ] = useState( [] );
  const useStyles = makeStyles( {
    table: {
      minWidth: 650,
    },
  } );

  function getStudent() {

    apiService.User.Students.Get().then( students => {
      setStudents( students.data );
      setIsLoading( false )
    } );
  }

  useEffect( getStudent, [] );
  const classes = useStyles();

  return (
    <MaterialTable

      columns={ values.columns }
      data={ students }
      actions={ [
        apiService.User.IsAdmin() || apiService.User.IsManagerSchool() ? {
          icon: 'edit',
          tooltip: 'Play file',
          onClick: ( event, rowData ) => {
            history.push( `students/${ rowData.id }` );
          }
        } : undefined,
        apiService.User.IsAdmin() || apiService.User.IsManagerSchool() ?
          {
            icon: 'add',
            tooltip: 'Add User',
            isFreeAction: true,
            onClick: ( event ) => {
              history.push( 'students/0' );
            }
          } : undefined
      ] }
      editable={ {
        // onRowAdd: apiService.User.IsAdmin() ? newData =>
        //   new Promise( ( resolve, reject ) => {
        //     apiService.User.Students.Insert( newData ).then( result => {
        //       if ( result.status ) {
        //         let data = [ ...students ];
        //         data.unshift( newData );
        //         setStudents( data );
        //         resolve();
        //       } else {
        //         alert( 'ארעה שגיאה בהכנסת נתונים. נא בדוק שכל הפרטים הוזנו כראוי' );
        //         resolve();
        //       }
        //     } );
        //   } ) : null,
        // onRowUpdate: ( newData, oldData ) =>
        //   new Promise( ( resolve, reject ) => {
        //     apiService.User.Students.Update( oldData.id, newData ).then( result => {
        //       if ( result.status ) {
        //         let data = [ ...students ];
        //         const index = data.findIndex( item => item.id === oldData.id );
        //         data[ index ] = newData;
        //         setStudents( data );
        //         resolve()
        //       } else {
        //         alert( 'ארעה שגיאה בעדכון נתונים. נא בדוק שכל הפרטים הוזנו כראוי' );
        //         resolve();
        //       }
        //     } );
        //
        //   } ),
        // onRowDelete: ( oldData ) =>
        //   new Promise( ( resolve, reject ) => {
        //     apiService.User.Students.Delete( oldData.id ).then( result => {
        //       if ( result.status ) {
        //         let data = [ ...students ];
        //         const index = data.findIndex( item => item.id === oldData.id );
        //         data.splice( index, 1 );
        //         setStudents( data );
        //         resolve()
        //       } else {
        //         alert( 'שגיאה במחיקת נתונים' );
        //         resolve()
        //       }
        //     } ).catch( e => {
        //       alert( 'ארעה שגיאה!' )
        //       resolve()
        //
        //     } );
        //
        //   } )
      } }
      isLoading={ isLoading }
      localization={ { header: { actions: 'פעולות' } } }
      options={ {

        filtering: true,
        exportButton: true,
        sorting: true,
        exportAllData: true,
        pageSize: 20,
        detailPanelColumnAlignment: 'left',
        addRowPosition: 'first'
      } }
      title={ <h2>רשימת תלמידים</h2> }
      searchFieldAlignment={ 'left' }
      onRowClick={ ( e, rowData ) => {
        props.history.push( `/app/logs/${ rowData.id }` );
      } }
      // icons={tableIcons}
    />
  )
};


export default UsersTable;
